import VrObject3D from '../Three/VrObject3D';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Camera from '../../Camera';
import Controlls from '../Controllers/Controlls';
import Resources from '../../Resources';

import { Color, RepeatWrapping, TextureLoader } from 'three';
import { Block, Text } from 'three-mesh-ui';

//@ts-ignore
import FontJSON from '../../../../static/assets/fonts/custom-roboto-regular-msdf.json';
//@ts-ignore
import FontImage from '../../../../static/assets/fonts/custom-roboto-regular.png';
import { autoInjectable } from 'tsyringe';
import IconScrollLeftWhite from '../../../../static/assets/icons/icon-name-scroll-left-white-full.png';
import IconScrollRightWhite from '../../../../static/assets/icons/icon-name-scroll-right-white-full.png';
import Utils from '../../Utils/Utils';
import AssignmentsHttpClient from '../../Network/AssignmentsHttpClient';
import IconScrollUpWhite from '../../../../static/assets/icons/icon-name-scroll-up-white-full.png';
import IconScrollDownWhite from '../../../../static/assets/icons/icon-name-scroll-down-white-full.png';
import TranslationService from '../../Translations/TranslationService';

@autoInjectable()
export class Calendar extends VrObject3D {
    public container: Block;
    public mainCalendarContent: Block;
    public currentlyDisplayedMonth: number;
    public currentlyDisplayedYear: number;

    public daysInSelectedMonth: number;

    public calendarEventText: Text;
    public calendarEventTextParent: Block; //calendarEventWrapper - do którego będzie wrzucany widok z wydarzeniami

    public calendarEventStartDate: Text;
    public calendarEventEndDate: Text;

    public allEvents: Object[];

    public initRequestPayload: {
        from_due_date: string;
        page_number: number;
        page_size: number;
        to_due_date: string;
    };

    public selectedDay: Block;

    private textureLoader: TextureLoader;

    public textures;

    public userType: string;

    public constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public camera?: Camera,
        public controls?: Controlls,
        public resources?: Resources,
        public assignmentsHttpClient?: AssignmentsHttpClient,
    ) {
        super();

        this.textureLoader = new TextureLoader();
        this.fetchIcon();

        this.currentlyDisplayedMonth = new Date().getMonth();
        this.currentlyDisplayedYear = new Date().getFullYear();

        const requestPayload = this.createRequestPayload(
            this.currentlyDisplayedYear,
            this.currentlyDisplayedMonth,
        );

        this.initRequestPayload = requestPayload;

        this.fetchData(this.initRequestPayload);
    }

    // create request to API
    private createRequestPayload(year, month) {
        let lastDayOfMonth;
        month = isNaN(month) ? Number(month) + 1 : month + 1;
        year = isNaN(year) ? Number(year) : year;
        lastDayOfMonth = this.getDaysInMonth(year, month).toString();
        month =
            Number(month).toString().length === 1
                ? '0' + Number(month).toString()
                : Number(month).toString();

        const requestPayload = {
            from_due_date: year + '-' + month + '-01T23:00:00.000Z',
            page_number: 0,
            page_size: 100,
            to_due_date:
                year + '-' + month + '-' + lastDayOfMonth + 'T23:00:00.000Z',
        };

        return requestPayload;
    }
    //
    private async fetchIcon() {
        this.textures = {
            IconScrollRightWhite: await this.textureLoader.loadAsync(
                IconScrollRightWhite,
            ),
            IconScrollLeftWhite: await this.textureLoader.loadAsync(
                IconScrollLeftWhite,
            ),
            IconScrollUpWhite: await this.textureLoader.loadAsync(
                IconScrollUpWhite,
            ),
            IconScrollDownWhite: await this.textureLoader.loadAsync(
                IconScrollDownWhite,
            ),
        };

        this.init();
    }

    private async fetchData(requestPayload, anotherMonth?) {
        let assignmentsEvents;

        if (!this.userType) {
            const _userType = await this.httpClient.getUser();

            if (_userType.data && _userType.data.is_school_student) {
                this.userType = 'student';
            } else if (_userType.data && _userType.data.is_school_teacher) {
                this.userType = 'teacher';
            }
        }

        if (this.userType === 'student') {
            assignmentsEvents =
                await this.assignmentsHttpClient.getAssignmentsForStudent(
                    requestPayload,
                );
        } else if (this.userType === 'teacher') {
            assignmentsEvents =
                await this.assignmentsHttpClient.getAssignmentsForTeacher(
                    requestPayload,
                );
        }

        const calendarEvents = await this.httpClient.getCalendarEvent();
        const events = [];
        // current month format MM ('2023-11-09T11:59:00.000Z')
        let _m =
            (Number(this.currentlyDisplayedMonth) + 1).toString().length === 1
                ? '0' + (Number(this.currentlyDisplayedMonth) + 1).toString()
                : (Number(this.currentlyDisplayedMonth) + 1).toString();

        if (
            assignmentsEvents.data &&
            assignmentsEvents.data.hasOwnProperty('objects') &&
            assignmentsEvents.data.objects.length > 0
        ) {
            for (let i = 0; i < assignmentsEvents.data.objects.length; i++) {
                let _assignmentsEvents = {
                    eventStart: assignmentsEvents.data.objects[i].start_date,
                    eventEnd: assignmentsEvents.data.objects[i].due_date,
                    eventTitle: assignmentsEvents.data.objects[i].title,
                    eventAllDay: false,
                    dayEvent: assignmentsEvents.data.objects[i].due_date
                        .split('T')[0]
                        .includes(this.currentlyDisplayedYear + '-' + _m)
                        ? //@ts-ignore
                          assignmentsEvents.data.objects[i].due_date.split(
                              'T',
                          )[0]
                        : //@ts-ignore
                          assignmentsEvents.data.objects[i].start_date.split(
                              'T',
                          )[0],
                };
                events.push(_assignmentsEvents);
            }
        }

        if (
            calendarEvents &&
            calendarEvents.data &&
            calendarEvents.data.length > 0
        ) {
            for (let i = 0; i < calendarEvents.data.length; i++) {
                if (
                    calendarEvents.data[i].start_date.includes(
                        this.currentlyDisplayedYear + '-' + _m,
                    )
                ) {
                    let _calendarEvents = {
                        eventStart: calendarEvents.data[i].start_date,
                        eventEnd: calendarEvents.data[i].end_date,
                        eventTitle: Utils.removeHtmlTags(
                            calendarEvents.data[i].text,
                        ),
                        eventAllDay: calendarEvents.data[i].is_all_day,
                        dayEvent:
                            calendarEvents.data[i].start_date.split('T')[0],
                    };
                    events.push(_calendarEvents);
                }
            }
        }

        this.allEvents = events;
        anotherMonth ? this.drawMonth(anotherMonth) : this.drawMonth();
    }

    private createCalendar() {
        let borderWidth = 0.003;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.5;
        let offsetActiveObj = 0.05;

        const mainCalendarWrapper = new Block({
            width: 1.5,
            height: 2.5,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontColor: new Color(0xffffff),
            offset: 0.01,
            backgroundOpacity: 0.001,
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: 0,
        });

        const mainCalendarContent = new Block({
            width: 1.3,
            height: 1.5,
            backgroundColor: new Color(0xffffff),
            backgroundOpacity: 0.0001,
            borderWidth: 0,
        });

        //---
        const btnChangeMonthWrapper = new Block({
            width: 1.3,
            height: 0.2,
            backgroundOpacity: 0,
            borderWidth: 0,
            contentDirection: 'row',
            justifyContent: 'space-between',
            margin: 0.02,
        });

        // -----
        const btnNextMonth = new Block({
            height: 0.1,
            width: 0.1,
            backgroundOpacity: 0,
            offset: 0.01,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });

        btnNextMonth.add(
            new Block({
                width: 0.08,
                height: 0.08,
                offset: 0.0001,
                backgroundTexture: this.textures.IconScrollRightWhite,
                borderWidth: 0,
            }),
        );
        this.textures.IconScrollRightWhite.wrapS = RepeatWrapping;
        this.textures.IconScrollRightWhite.wrapT = RepeatWrapping;

        // -----
        const btnPrevMonth = new Block({
            height: 0.1,
            width: 0.1,
            backgroundOpacity: 0,
            offset: 0.01,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });

        btnPrevMonth.add(
            new Block({
                width: 0.08,
                height: 0.08,
                offset: 0.0001,
                backgroundTexture: this.textures.IconScrollLeftWhite,
                borderWidth: 0,
            }),
        );
        this.textures.IconScrollLeftWhite.wrapS = RepeatWrapping;
        this.textures.IconScrollLeftWhite.wrapT = RepeatWrapping;

        this.intersectionContainer.addObjectToIntersect(btnNextMonth, false);
        this.intersectionContainer.addObjectToIntersect(btnPrevMonth, false);

        this.setDefaultState(btnNextMonth, () => {
            // usunięcie zaznaczonego dnia z wydarzeniem
            if (
                this.calendarEventTextParent &&
                //@ts-ignore
                this.calendarEventTextParent.childrenBoxes.length > 0
            ) {
                this.removeObject(
                    //@ts-ignore
                    this.calendarEventTextParent.childrenBoxes[0],
                );
            }

            let _month = (this.currentlyDisplayedMonth += 1);
            let _year = this.currentlyDisplayedYear;

            if (_month > 11) {
                _month = 0;
                _year += 1;
            }

            // let requestPayload = this.createRequestPayload(this.currentlyDisplayedYear, this.currentlyDisplayedMonth +1);
            const requestPayload = this.createRequestPayload(_year, _month);
            this.fetchData(requestPayload, 1);
        });

        this.setDefaultState(btnPrevMonth, () => {
            // usunięcie zaznaczonego dnia z wydarzeniem
            //@ts-ignore
            if (
                this.calendarEventTextParent &&
                //@ts-ignore
                this.calendarEventTextParent.childrenBoxes.length > 0
            ) {
                //@ts-ignore
                this.removeObject(
                    //@ts-ignore
                    this.calendarEventTextParent.childrenBoxes[0],
                );
            }

            let _month = (this.currentlyDisplayedMonth -= 1);
            let _year = this.currentlyDisplayedYear;

            if (_month < 0) {
                _month = 11;
                _year -= 1;
            }

            const requestPayload = this.createRequestPayload(_year, _month);

            this.fetchData(requestPayload, -1);
        });

        btnChangeMonthWrapper.add(btnPrevMonth, btnNextMonth);
        mainCalendarWrapper.add(btnChangeMonthWrapper);
        mainCalendarWrapper.add(mainCalendarContent);
        this.mainCalendarContent = mainCalendarContent;

        //  **********************
        // calendar event wrapper
        const calendarEventWrapper = new Block({
            width: 1.3,
            height: 0.6,
            backgroundOpacity: 0,
            borderWidth: 0,
            textAlign: 'left',
            fontSize: 0.1,
            contentDirection: 'column',
            justifyContent: 'end',
        });
        calendarEventWrapper.name = 'calendarEventWrapper';

        const calendarEventStartDateWrapper = new Block({
            width: 1.3,
            height: 0.12,
            fontSize: 0.07,
            backgroundOpacity: 0,
            justifyContent: 'center',
        });

        const calendarEventEndDateWrapper = new Block({
            width: 1.3,
            height: 0.12,
            fontSize: 0.07,
            backgroundOpacity: 0,
            justifyContent: 'center',
        });

        const calendarEventTextWrapper = new Block({
            width: 1.3,
            height: 0.2,
            backgroundOpacity: 0,
            justifyContent: 'center',
        });

        const calendarEventText = new Text({
            fontSize: 0.08,
            content: TranslationService.translate('vr.calendar_no_events'),
        });

        const calendarEventStartDate = new Text({
            fontSize: 0.065,
            content: '',
        });

        const calendarEventEndDate = new Text({
            fontSize: 0.065,
            content: '',
        });

        const calendarEventNextBtn = new Block({
            width: 0.15,
            height: 0.15,
            backgroundColor: new Color(0xff0000),
            backgroundOpacity: 1,
        });

        const calendarEventPrevBtn = new Block({
            width: 0.15,
            height: 0.15,
            backgroundColor: new Color(0xff00ff),
            backgroundOpacity: 1,
        });

        calendarEventStartDateWrapper.add(calendarEventStartDate);
        calendarEventEndDateWrapper.add(calendarEventEndDate);
        calendarEventTextWrapper.add(calendarEventText);

        this.calendarEventText = calendarEventText;
        this.calendarEventStartDate = calendarEventStartDate;
        this.calendarEventEndDate = calendarEventEndDate;

        this.addEventListener('updateEventText', (e) => {
            // jeśli w danym dniu jest kilka wydarzeń:
            if (Array.isArray(e.eventDetails) && e.eventDetails.length > 0) {
                const eventCardWrapper = new Block({
                    width: 1.3,
                    height: 0.6,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    textAlign: 'left',
                    fontSize: 0.1,
                    contentDirection: 'row',
                    hiddenOverflow: true,
                });
                eventCardWrapper.name = 'eventCardWrapper';

                const allEventsW = new Block({
                    width: 1.2,
                    height: 0.6,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    fontSize: 0.1,
                    contentDirection: 'column',
                });

                const eventCardScrollWrapper = new Block({
                    width: 0.1,
                    height: 0.6,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    contentDirection: 'column',
                    justifyContent: 'space-between',
                });

                const btnPrevEvent = new Block({
                    height: 0.1,
                    width: 0.1,
                    backgroundOpacity: 1,
                    offset: 0.001,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                const btnNextEvent = new Block({
                    height: 0.1,
                    width: 0.1,
                    backgroundOpacity: 1,
                    offset: 0.001,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                btnPrevEvent.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        offset: 0.0001,
                        backgroundTexture: this.textures.IconScrollUpWhite,
                        borderWidth: 0,
                    }),
                );
                this.textures.IconScrollUpWhite.wrapS = RepeatWrapping;
                this.textures.IconScrollUpWhite.wrapT = RepeatWrapping;

                btnNextEvent.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        offset: 0.0001,
                        backgroundTexture: this.textures.IconScrollDownWhite,
                        borderWidth: 0,
                    }),
                );
                this.textures.IconScrollDownWhite.wrapS = RepeatWrapping;
                this.textures.IconScrollDownWhite.wrapT = RepeatWrapping;

                eventCardScrollWrapper.add(btnPrevEvent, btnNextEvent);

                this.intersectionContainer.addObjectToIntersect(
                    btnPrevEvent,
                    false,
                );
                this.intersectionContainer.addObjectToIntersect(
                    btnNextEvent,
                    false,
                );

                this.setDefaultState(btnPrevEvent, () => {
                    //@ts-ignore
                    allEventsW.position.y <= -allEventsW.size.y ||
                    allEventsW.position.y <= 0
                        ? (allEventsW.position.y -= 0)
                        : (allEventsW.position.y -= 0.6);
                });

                this.setDefaultState(btnNextEvent, () => {
                    // @ts-ignore
                    let _maxScroll = 0;
                    // @ts-ignore
                    for (let i = 0; i < allEventsW.childrenBoxes.length; i++) {
                        // @ts-ignore
                        _maxScroll += allEventsW.childrenBoxes[i].height;
                    }

                    allEventsW.position.y < _maxScroll
                        ? (allEventsW.position.y += 0.6)
                        : (allEventsW.position.y += 0);
                });

                e.eventDetails.length === 1
                    ? eventCardWrapper.add(allEventsW)
                    : eventCardWrapper.add(allEventsW, eventCardScrollWrapper);

                // wydarzenie
                e.eventDetails.forEach((event) => {
                    const eventCard = new Block({
                        width: 1.2,
                        height: 0.6,
                        backgroundOpacity: 0,
                        borderWidth: 0.001,
                        borderColor: new Color(0xff000),
                        textAlign: 'left',
                        fontSize: 0.1,
                        contentDirection: 'column',
                        justifyContent: 'end',
                    });
                    eventCard.name = event.eventTitle;

                    const calendarEventStartDateWrapper = new Block({
                        width: 1.2,
                        height: 0.12,
                        fontSize: 0.07,
                        backgroundOpacity: 0,
                        justifyContent: 'center',
                    });
                    calendarEventStartDateWrapper.name = 'eventStart';

                    const calendarEventEndDateWrapper = new Block({
                        width: 1.2,
                        height: 0.12,
                        fontSize: 0.07,
                        backgroundOpacity: 0,
                        justifyContent: 'center',
                    });
                    calendarEventEndDateWrapper.name = 'eventEnd';

                    let calendarEventTextWrapper = new Block({
                        width: 1.2,
                        height: 0.2,
                        backgroundOpacity: 0,
                        justifyContent: 'center',
                    });
                    calendarEventTextWrapper.name = 'eventText';

                    const calendarEventText = new Text({
                        fontSize: 0.08,
                        content: event.eventTitle,
                    });

                    const calendarEventStartDate = new Text({
                        fontSize: 0.065,
                        content: event.eventAllDay
                            ? ''
                            : Utils.formatPublicationDate(event.eventStart),
                    });

                    const calendarEventEndDate = new Text({
                        fontSize: 0.065,
                        content: event.eventAllDay
                            ? 'Cały dzień'
                            : Utils.formatPublicationDate(event.eventEnd),
                    });

                    eventCard.add(
                        calendarEventStartDateWrapper.add(
                            calendarEventStartDate,
                        ),
                        calendarEventEndDateWrapper.add(calendarEventEndDate),
                        calendarEventTextWrapper.add(calendarEventText),
                    );

                    allEventsW.add(eventCard);
                });
                this.calendarEventTextParent.add(eventCardWrapper);
            }
            // jeśli w danym dniu nie ma wydarzenia:
            else {
                const eventCardWrapper = new Block({
                    width: 1.3,
                    height: 0.6,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    textAlign: 'left',
                    fontSize: 0.1,
                    contentDirection: 'row',
                    hiddenOverflow: true,
                });
                eventCardWrapper.name = 'eventCardWrapper';

                const eventCard = new Block({
                    width: 1.3,
                    height: 0.6,
                    backgroundOpacity: 0,
                    borderWidth: 0.001,
                    textAlign: 'left',
                    fontSize: 0.1,
                    justifyContent: 'end',
                });
                eventCard.name = e.eventDetails.eventTitle;

                const calendarEventStartDateWrapper = new Block({
                    width: 1.3,
                    height: 0.12,
                    backgroundOpacity: 0,
                });
                calendarEventStartDateWrapper.name = 'eventStart';

                const calendarEventEndDateWrapper = new Block({
                    width: 1.3,
                    height: 0.12,
                    backgroundOpacity: 0,
                });
                calendarEventEndDateWrapper.name = 'eventEnd';

                const calendarEventTextWrapper = new Block({
                    width: 1.3,
                    height: 0.2,
                    backgroundOpacity: 0,
                    justifyContent: 'center',
                });
                calendarEventTextWrapper.name = 'eventText';

                const calendarEventText = new Text({
                    fontSize: 0.08,
                    content: e.eventDetails.eventTitle,
                });

                eventCard.add(
                    calendarEventStartDateWrapper,
                    calendarEventEndDateWrapper,
                    calendarEventTextWrapper.add(calendarEventText),
                );

                eventCardWrapper.add(eventCard);

                this.calendarEventTextParent.add(eventCardWrapper);
            }
        });

        this.calendarEventTextParent = calendarEventWrapper;
        mainCalendarWrapper.add(calendarEventWrapper);

        this.container = mainCalendarWrapper;
        this.add(mainCalendarWrapper);
    }

    private drawMonth(anotherMonth?: number) {
        //--------------------------------------------------------------------------------------------------------------
        if (anotherMonth) {
            const _objToRemove = this.getObjectByName('calendar');
            this.removeObject(_objToRemove);
        }

        const calendar = new Block({
            width: 1.3,
            height: 2,
            backgroundOpacity: 0,
            borderWidth: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            offset: 0.2,
        });
        calendar.name = 'calendar';
        calendar.position.y = 0.23;

        let date = new Date();
        let currYear;
        let currMonth;

        if (!anotherMonth) {
            currYear = date.getFullYear();
            currMonth = date.getMonth();

            this.currentlyDisplayedMonth = currMonth;
            this.currentlyDisplayedYear = currYear;
        } else {
            //if Month -1
            if (anotherMonth === -1) {
                if (this.currentlyDisplayedMonth < 0) {
                    currMonth = this.currentlyDisplayedMonth = 11;
                    this.currentlyDisplayedYear -= 1;
                    currYear = this.currentlyDisplayedYear;
                } else {
                    currMonth = this.currentlyDisplayedMonth;
                    currYear = this.currentlyDisplayedYear;
                }
                //if Month +1
            } else {
                if (this.currentlyDisplayedMonth > 11) {
                    this.currentlyDisplayedMonth = 0;
                    currMonth = this.currentlyDisplayedMonth;

                    this.currentlyDisplayedYear += 1;
                    currYear = this.currentlyDisplayedYear;
                } else {
                    currMonth = this.currentlyDisplayedMonth;
                    currYear = this.currentlyDisplayedYear;
                }
            }
        }

        //All days in month view (with the last days of the previous month and the first days of the next month)
        const allDayOnPage = [];

        const firstDayofMonth = new Date(currYear, currMonth, 1).getDay(); // getting first day of month
        const lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(); // getting last date of month
        const lastDayofMonth = new Date(
            currYear,
            currMonth,
            lastDateofMonth,
        ).getDay(); // getting last day of month
        const lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month

        //last days of the previous month
        let _lastDays = '';
        for (let i = firstDayofMonth - 1; i > 0; i--) {
            allDayOnPage.push(lastDateofLastMonth - i + 1 + 'P');
            _lastDays += lastDateofLastMonth - i + 1 + 'P' + ' , ';
        }

        //days of the current month
        for (let i = 1; i <= lastDateofMonth; i++) {
            // adding 'T' letter to current day, month, and year matched
            let day =
                i === date.getDate() &&
                currMonth === new Date().getMonth() &&
                currYear === new Date().getFullYear()
                    ? i.toString() + 'T'
                    : i.toString();

            allDayOnPage.push(day);
        }

        //days of the next month
        let _nextDay = '';
        if (lastDayofMonth != 0) {
            let _daysNextMonth = 7 - lastDayofMonth;
            for (let i = 1; i <= _daysNextMonth; i++) {
                allDayOnPage.push(i + 'N');
                _nextDay += i + 'N' + ' , ';
            }
        }

        //Month name
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const monthName = new Block({
            // width: 1.3,
            width: 1,
            height: 0.2,
            justifyContent: 'center',
            textAlign: 'center',
            backgroundOpacity: 0,
            fontColor: new Color(0xffffff),
            // fontSize: 0.05
            fontSize: 0.09,
            borderWidth: 0,
        });

        monthName.add(
            new Text({
                content: months[currMonth] + ' ' + currYear,
            }),
        );

        calendar.add(monthName);

        //main calendar content
        const mainCalendar = new Block({
            width: 1.3,
            height: 1.3,
            backgroundOpacity: 0,
            contentDirection: 'column',
            borderWidth: 0,
        });

        calendar.add(mainCalendar);

        //Day name
        const dayName = [
            TranslationService.translate('vr.calendar_mo'),
            TranslationService.translate('vr.calendar_tu'),
            TranslationService.translate('vr.calendar_we'),
            TranslationService.translate('vr.calendar_th'),
            TranslationService.translate('vr.calendar_fr'),
            TranslationService.translate('vr.calendar_sa'),
            TranslationService.translate('vr.calendar_su'),
        ];

        const calendarDayWrapper = new Block({
            width: 1.3, //1.5
            height: 0.2,
            contentDirection: 'row',
            justifyContent: 'space-around',
            backgroundOpacity: 0,
            borderWidth: 0,
        });

        for (let i = 0; i < dayName.length; i++) {
            const calendarDayy = new Block({
                width: 0.15,
                height: 0.15,
                backgroundOpacity: 0,
                justifyContent: 'center',
                textAlign: 'center',
                fontColor: new Color(0xffffff),
                fontSize: 0.09,
                borderWidth: 0,
            });

            calendarDayy.add(
                new Text({
                    content: dayName[i],
                }),
            );

            calendarDayWrapper.add(calendarDayy);
        }
        mainCalendar.add(calendarDayWrapper);

        //all days
        const allWeek = [];
        for (let i = 0; i < allDayOnPage.length; i += 7) {
            const week = allDayOnPage.slice(i, i + 7);
            allWeek.push(week);
        }

        // Create calendar row with week
        for (let i = 0; i < allWeek.length; i++) {
            const week = new Block({
                width: 1.3,
                height: 0.2,
                contentDirection: 'row',
                justifyContent: 'space-around',
                backgroundOpacity: 0,
                borderWidth: 0,
            });

            for (let j = 0; j < allWeek[i].length; j++) {
                const calendarDay = new Block({
                    width: 0.15,
                    height: 0.15,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontColor: new Color(0xffffff),
                    fontSize: 0.09,
                    offset: 0.1,
                });

                //-----------------------------
                //create block object with day number
                //'P' - prev month days | 'T' - today | 'N' next month days

                // MONTH days
                if (
                    allWeek[i][j].indexOf('P') === -1 &&
                    allWeek[i][j].indexOf('T') === -1 &&
                    allWeek[i][j].indexOf('N') === -1
                ) {
                    const _dmy =
                        currYear +
                        '-' +
                        ((currMonth + 1).toString().length === 1
                            ? '0' + (currMonth + 1).toString()
                            : (currMonth + 1).toString()) +
                        '-' +
                        (allWeek[i][j].toString().length === 1
                            ? '0' + allWeek[i][j].toString()
                            : allWeek[i][j].toString());

                    calendarDay.add(
                        new Text({
                            content: allWeek[i][j],
                        }),
                    );

                    calendarDay.userData.date = _dmy;
                    calendarDay.userData.event = this.allEvents.filter(
                        (event) => {
                            //@ts-ignore
                            return event.dayEvent === _dmy;
                        },
                    );

                    calendarDay.userData.isSelected = false;

                    week.add(calendarDay);

                    // TODAY
                } else if (allWeek[i][j].indexOf('T') != -1) {
                    const _dmy =
                        allWeek[i][j].slice(0, -1) +
                        '.' +
                        (currMonth + 1) +
                        '.' +
                        currYear;

                    calendarDay.add(
                        new Text({
                            content: allWeek[i][j].slice(0, -1),
                            fontColor: new Color(0xffffff),
                        }),
                    );

                    calendarDay.userData.date = _dmy;
                    calendarDay.userData.today = true;
                    calendarDay.userData.event = this.allEvents.filter(
                        (event) => {
                            //@ts-ignore
                            return event.dayEvent === _dmy;
                        },
                    );

                    calendarDay.userData.isSelected = false;
                    week.add(calendarDay);

                    // PREV month days
                } else if (allWeek[i][j].indexOf('P') != -1) {
                    const _dmy =
                        allWeek[i][j].slice(0, -1) +
                        '.' +
                        currMonth +
                        '.' +
                        currYear;

                    calendarDay.add(
                        new Text({
                            content: allWeek[i][j].slice(0, -1),
                            fontColor: new Color(0x92b77b),
                        }),
                    );

                    calendarDay.userData.date = _dmy;
                    calendarDay.userData.daysFromPrevMonth = true;
                    calendarDay.userData.event = this.allEvents.filter(
                        (event) => {
                            //@ts-ignore
                            return event.dayEvent === _dmy;
                        },
                    );

                    calendarDay.userData.isSelected = false;

                    week.add(calendarDay);

                    // NEXT month days
                } else if (allWeek[i][j].indexOf('N') != -1) {
                    const _dmy =
                        allWeek[i][j].slice(0, -1) +
                        '.' +
                        (currMonth + 2) +
                        '.' +
                        currYear;

                    calendarDay.add(
                        new Text({
                            content: allWeek[i][j].slice(0, -1),
                            fontColor: new Color(0x92b77b),
                        }),
                    );

                    calendarDay.userData.date = _dmy;
                    calendarDay.userData.daysFromNextMonth = true;
                    calendarDay.userData.event = this.allEvents.filter(
                        (event) => {
                            //@ts-ignore
                            return event.dayEvent === _dmy;
                        },
                    );

                    calendarDay.userData.isSelected = false;

                    week.add(calendarDay);
                }

                //set font Color for day with event
                calendarDay.userData.event &&
                calendarDay.userData.event.length > 0
                    ? //@ts-ignore
                      calendarDay.set({ fontColor: new Color(0x333333) })
                    : //@ts-ignore
                      calendarDay.set({ fontColor: new Color(0xffffff) });

                this.intersectionContainer.addObjectToIntersect(calendarDay);

                // click na wybranym dniu
                this.setDefaultState(calendarDay, () => {
                    // usunięcie wcześniej wyświetlanego bloku z informacją o wydarzeniachw danym dniu
                    //@ts-ignore
                    if (
                        this.calendarEventTextParent &&
                        //@ts-ignore
                        this.calendarEventTextParent.childrenBoxes.length > 0
                    ) {
                        //@ts-ignore
                        this.removeObject(
                            //@ts-ignore
                            this.calendarEventTextParent.childrenBoxes[0],
                        );
                    }

                    if (calendarDay.userData.hasOwnProperty('event')) {
                        let eventDetails;

                        // jeśli w danym dniu są wydarzenia
                        if (calendarDay.userData.event.length > 0) {
                            eventDetails = calendarDay.userData.event;
                        } else {
                            // jeśli w danym dniu nie ma wydarzenia
                            eventDetails = {
                                eventTitle: '',
                                startDate: '',
                                endDate: '',
                            };

                            eventDetails.eventTitle =
                                TranslationService.translate(
                                    'vr.calendar_no_events',
                                );
                        }

                        this.dispatchEvent({
                            type: 'updateEventText',
                            eventDetails,
                        });
                    }

                    // set border to select day
                    if (this.selectedDay) {
                        this.selectedDay.userData.isSelected = false;
                        //@ts-ignore
                        this.selectedDay.set({
                            borderWidth: 0,
                            borderColor: new Color(0xffffff),
                            borderOpacity: 0,
                        });
                    }

                    calendarDay.userData.isSelected = true;

                    this.selectedDay = calendarDay;
                    //@ts-ignore
                    calendarDay.set({
                        borderWidth: 0.0025,
                        borderColor: new Color(0xffffff),
                        borderOpacity: 1,
                        borderRadius: [0.075, 0.075, 0.075, 0.075],
                    });
                });
            }
            mainCalendar.add(week);
        }

        this.add(calendar);
    }

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //

        if (
            block.userData.hasOwnProperty('today') &&
            block.userData['today'] === true
        ) {
            //@ts-ignore
            block.setupState({
                state: 'idle',
                attributes: {
                    border: 1,
                    backgroundOpacity: 0.6,
                    backgroundColor: new Color(0xffffff),
                },
            });

            // if today has event display event info; if not display 'no event'
            if (
                block.userData.hasOwnProperty('event') &&
                block.userData.event.length > 0
            ) {
                let eventDetails = block.userData.event;

                this.dispatchEvent({
                    type: 'updateEventText',
                    eventDetails,
                });
            } else {
                let eventDetails = {
                    eventTitle: TranslationService.translate(
                        'vr.calendar_no_events',
                    ),
                    startDate: '',
                    endDate: '',
                };
                this.dispatchEvent({
                    type: 'updateEventText',
                    eventDetails,
                });
            }
        } else {
            //@ts-ignore
            block.setupState({
                state: 'idle',
                attributes: {
                    backgroundOpacity: 0.001,
                },
            });
        }

        if (
            block.userData.hasOwnProperty('today') &&
            block.userData['today'] === true
        ) {
            //@ts-ignore
            block.setupState({
                state: 'idle',
                attributes: {
                    border: 1,
                    backgroundOpacity: 0.3,
                    backgroundColor: new Color(0xffffff),
                    borderRadius: [0.075, 0.075, 0.075, 0.075],
                    borderWidth: 0.005,
                    borderColor: new Color(0xffffff),
                    borderOpacity: 1,
                },
            });
        } else {
            //@ts-ignore
            block.setupState({
                state: 'hovered',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
            });
        }

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    public removeObject(obj) {
        const allChild = [];

        obj.traverse((child) => {
            if (child instanceof Block || Text || VrObject3D) {
                allChild.push(child);
            }
        });

        let all = this.intersectionContainer.objectsToIntersect;
        all = all.filter(
            (one) => !allChild.find((two) => one.uuid == two.uuid),
        );
        this.intersectionContainer.objectsToIntersect = all;

        const parent = obj.parent;
        parent.remove(obj);
    }

    public getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    public init() {
        this.createCalendar();
    }
}
