import Renderer from '../../Components/Renderer';
import { container } from 'tsyringe';
import React, { useEffect, useState } from 'react';
import VrScene from '../../Components/Three/VrScene';
import PrivateRoomScene from '../../Scenes/PrivateRoom/PrivateRoomScene';
import { FaMicrophone } from 'react-icons/fa';
import TranslationService from '../../Translations/TranslationService';

const ChatbotButtons = () => {
    const translationService = container.resolve(TranslationService);
    const renderer = container.resolve(Renderer);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [sound, setSound] = useState(null);

    const chatbot = window.chatbot;

    chatbot.addEventListener('startRecording', () => {
        setIsRecording(true);
    });

    chatbot.addEventListener('stopRecording', () => {
        setIsRecording(false);
    });

    chatbot.addEventListener('soundStarted', (event) => {
        setIsSpeaking(true);
    });

    chatbot.addEventListener('soundEnded', (event) => {
        setIsSpeaking(false);
    });

    const startRecording = () => {
        chatbot.startRecording();
        setIsRecording(true);
    };

    const stopRecording = () => {
        chatbot.stopRecording();
        setIsRecording(false);
    };

    const stopPlaying = () => {
        chatbot.stopPlaying();
    };

    const MuteButton = () => {
        if (!isSpeaking) {
            return <></>;
        }

        return (
            <button className="additional-button" onClick={stopPlaying}>
                {translationService.l('vr.menu_stop_speaking')}
            </button>
        );
    };

    const RecordingButton = () => {
        if (!isRecording) {
            return (
                <button
                    className="additional-button"
                    onClick={startRecording}
                    disabled={isRecording}
                >
                    {translationService.l('vr.menu_ask_aI_tutor')}{' '}
                    <FaMicrophone />
                </button>
            );
        }

        return (
            <button className="additional-button red" onClick={stopRecording}>
                {translationService.l('vr.menu_stop_recording')}
            </button>
        );
    };

    return (
        <>
            <RecordingButton />
            <MuteButton />
        </>
    );
};

export default ChatbotButtons;
