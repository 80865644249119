import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import { Block } from 'three-mesh-ui';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import {
    TextureLoader,
    MeshBasicMaterial,
    Mesh,
    PlaneGeometry,
    RepeatWrapping,
    Texture,
} from 'three';
//@ts-ignore
import FontJSON from '../../../../static/assets/fonts/Roboto-Regular-msdf.json';
//@ts-ignore
import FontImage from '../../../../static/assets/fonts/Roboto-Regular.png';

//icons
import iconMaskLoader from '../../../../static/assets/icons/icon-maskLoader.png';

export interface IAnimationTexture {
    currentTile: number;
    tileDisplayDuration: number;
    stop: Function;
    texture: Texture;
    nextFrame: Function;
    start: Function;
    numberOfTiles: number;
    intervalID: number;
    tilesHorizontal: number;
    tilesVertical: number;
}

@autoInjectable()
export class MaskLoader extends VrObject3D {
    public container: Block;
    public loaderWrapper: Block;
    public animation: IAnimationTexture;

    public constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
    ) {
        super();

        this.createMask();
    }

    public async createMask() {
        let maskWidth = 2;
        let maskHeight = 1;
        let loaderWrapperWidth = maskWidth / 2;
        let loaderWrapperHeight = maskHeight / 2;

        const container = new Block({
            width: maskWidth,
            height: maskHeight,
            backgroundOpacity: 0,
            justifyContent: 'center',
        });

        const loaderWrapper = new Block({
            width: loaderWrapperWidth,
            height: loaderWrapperHeight,
            contentDirection: 'row',
            backgroundOpacity: 0,
            offset: 0.02,
        });

        container.add(loaderWrapper);
        this.add(container);
        this.container = container;
        this.loaderWrapper = loaderWrapper;

        const animatedTexture = new TextureLoader().load(iconMaskLoader);

        this.animation = this.textureAnimation(animatedTexture, 5, 1, 60);

        const animatedMaterial = new MeshBasicMaterial({
            map: animatedTexture,
            transparent: true,
        });
        const animatedGeometry = new PlaneGeometry(
            loaderWrapperWidth,
            loaderWrapperHeight,
        );
        const animatedMesh = new Mesh(animatedGeometry, animatedMaterial);

        this.loaderWrapper.add(animatedMesh);
    }

    public textureAnimation(
        texture: Texture,
        tilesHoriz: number,
        tilesVert: number,
        tileDisplayDuration: number,
    ) {
        let obj: IAnimationTexture = {
            texture: undefined,
            tilesHorizontal: undefined,
            tilesVertical: undefined,
            tileDisplayDuration: undefined,
            numberOfTiles: undefined,
            currentTile: undefined,
            nextFrame: undefined,
            start: undefined,
            stop: undefined,
            intervalID: undefined,
        };

        obj.texture = texture;
        obj.tilesHorizontal = tilesHoriz;
        obj.tilesVertical = tilesVert;
        obj.tileDisplayDuration = tileDisplayDuration;
        obj.numberOfTiles = tilesHoriz * tilesVert;
        obj.texture.wrapS = RepeatWrapping;
        obj.texture.wrapT = RepeatWrapping;
        obj.texture.repeat.set(1 / tilesHoriz, 1 / tilesVert);
        obj.currentTile = 0;

        obj.nextFrame = () => {
            obj.currentTile++;
            if (obj.currentTile == obj.numberOfTiles) obj.currentTile = 0;

            let currentColumn = obj.currentTile % obj.tilesHorizontal;
            obj.texture.offset.x = currentColumn / obj.tilesHorizontal;

            let currentRow = Math.floor(obj.currentTile / obj.tilesHorizontal);
            obj.texture.offset.y =
                obj.tilesVertical - currentRow / obj.tilesVertical;
        };

        obj.start = () => {
            obj.intervalID = setInterval(
                obj.nextFrame,
                obj.tileDisplayDuration,
            );
        };

        obj.stop = () => {
            clearInterval(obj.intervalID);
        };

        obj.start();

        return obj;
    }

    public removeMask(): void {
        this.container.removeFromParent();
    }
}
