import { useState, useEffect, useCallback } from 'react';
import ColyseusClient from '../../../Network/ColyseusClient';
import { container } from 'tsyringe';
import Renderer from '../../../Components/Renderer';

const TOTAL_SLOTS = 10;
const STORAGE_KEY = 'loadedModels';

interface LoadedModel {
    id: number;
    url: string;
    lessonName: string;
    icon: string;
}

interface RoomModels {
    [roomId: string]: LoadedModel[];
}

export const useLoadedModels = () => {
    const [loadedModels, setLoadedModels] = useState<RoomModels>({});
    const [currentModelIndex, setCurrentModelIndex] = useState(-1);
    const colyseus = container.resolve(ColyseusClient);
    const renderer = container.resolve(Renderer);

    useEffect(() => {
        const storedModels = localStorage.getItem(STORAGE_KEY);
        if (storedModels) {
            setLoadedModels(JSON.parse(storedModels));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(loadedModels));
    }, [loadedModels]);

    const getCurrentRoomId = useCallback(() => {
        //@ts-ignore
        return renderer.scene.roomId;
    }, [renderer.scene]);

    const addLoadedModel = useCallback(
        (model: LoadedModel) => {
            const roomId = getCurrentRoomId();
            setLoadedModels((prevModels) => {
                const roomModels = prevModels[roomId] || [];
                const existingIndex = roomModels.findIndex(
                    (m) => m.id === model.id,
                );
                let newRoomModels: LoadedModel[];

                if (existingIndex !== -1) {
                    newRoomModels = roomModels.filter(
                        (_, index) => index !== existingIndex,
                    );
                } else if (roomModels.length >= TOTAL_SLOTS) {
                    newRoomModels = roomModels.slice(1);
                } else {
                    newRoomModels = [...roomModels];
                }

                const updatedModels = {
                    ...prevModels,
                    [roomId]: [...newRoomModels, model],
                };

                return updatedModels;
            });
        },
        [getCurrentRoomId],
    );
    const removeModel = useCallback(
        (modelId: number) => {
            const roomId = getCurrentRoomId();
            setLoadedModels((prevModels) => ({
                ...prevModels,
                [roomId]: (prevModels[roomId] || []).filter(
                    (m) => m.id !== modelId,
                ),
            }));
            console.log('Updated models:', loadedModels);
        },

        [getCurrentRoomId],
    );

    const resetAllModels = useCallback(() => {
        const roomId = getCurrentRoomId();
        setLoadedModels((prevModels) => ({
            ...prevModels,
            [roomId]: [],
        }));
        updateModel3dViewer('none');
    }, [getCurrentRoomId]);

    const updateModel3dViewer = useCallback(
        (modelUrl: string) => {
            colyseus.updateModel3dViewer({
                modelUrl,
                modelVisibility: true,
                isAnimationPlaying: false,
                isModelRotating: false,
                modelRotationDirection: 1,
                modelRotationSpeed: 0,
                modelScale: 0,
            });
        },
        [colyseus],
    );

    const handleChangeModel = useCallback(
        (model: LoadedModel) => {
            updateModel3dViewer(model.url);
        },
        [updateModel3dViewer],
    );

    const handleLoadModels = useCallback(() => {
        const roomId = getCurrentRoomId();
        const roomModels = loadedModels[roomId] || [];
        if (roomModels.length > 0) {
            updateModel3dViewer(roomModels[0].url);
        }
    }, [loadedModels, updateModel3dViewer, getCurrentRoomId]);

    const getCurrentRoomModels = useCallback(() => {
        const roomId = getCurrentRoomId();
        return loadedModels[roomId] || [];
    }, [loadedModels, getCurrentRoomId]);

    return {
        loadedModels: getCurrentRoomModels(),
        addLoadedModel,
        removeModel,
        resetAllModels,
        handleChangeModel,
        handleLoadModels,
        totalSlots: TOTAL_SLOTS,
        currentModelIndex,
        setCurrentModelIndex,
    };
};
