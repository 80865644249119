import {
    Object3D,
    Box3,
    Vector3,
    Mesh,
    BufferGeometry,
    Material,
    MeshStandardMaterial,
    LOD,
    Quaternion,
    Matrix4,
} from 'three';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { Texture } from 'three/src/textures/Texture';

export class ModelOptimizer {
    private readonly simplifyModifier = new SimplifyModifier();
    private readonly matrix = new Matrix4();

    public optimizeModel(
        model: Object3D,
        options = {
            targetTriangles: 10000,
            preserveTextures: true,
            setupLOD: true,
            mergeMeshes: true,
            maxTextureSize: 1024,
        },
    ): Object3D {
        let totalTriangles = 0;
        model.traverse((child) => {
            if (child instanceof Mesh) {
                const geometry = child.geometry as BufferGeometry;
                if (geometry.index) {
                    totalTriangles += geometry.index.count / 3;
                } else {
                    totalTriangles += geometry.attributes.position.count / 3;
                }
            }
        });

        const reductionRatio = options.targetTriangles / totalTriangles;

        if (options.mergeMeshes) {
            model = this.mergeSimilarMeshes(model);
        }

        model.traverse((child) => {
            if (child instanceof Mesh) {
                this.optimizeMesh(child, reductionRatio, options);
            }
        });

        if (options.setupLOD) {
            model = this.setupLODLevels(model);
        }

        return model;
    }

    private optimizeMesh(mesh: Mesh, reductionRatio: number, options: any): void {
        if (!mesh.geometry) return;

        const originalUVs = mesh.geometry.attributes.uv?.array;
        const originalNormals = mesh.geometry.attributes.normal?.array;
        const currentTriangles = mesh.geometry.index
            ? mesh.geometry.index.count / 3
            : mesh.geometry.attributes.position.count / 3;

        const targetTriangles = Math.max(
            Math.floor(currentTriangles * reductionRatio),
            100,
        );

        try {
            const simplified = this.simplifyModifier.modify(mesh.geometry, targetTriangles);

            if (options.preserveTextures && originalUVs) {
                this.preserveUVs(simplified, originalUVs);
            }

            if (originalNormals) {
                this.preserveNormals(simplified, originalNormals);
            }

            simplified.attributes.position.needsUpdate = true;
            if (simplified.attributes.normal) {
                simplified.attributes.normal.needsUpdate = true;
            }
            if (simplified.attributes.uv) {
                simplified.attributes.uv.needsUpdate = true;
            }

            mesh.geometry = simplified;
        } catch (error) {
            console.warn('Failed to simplify mesh:', error);
        }

        if (mesh.material) {
            this.optimizeMaterial(mesh.material as MeshStandardMaterial, options);
        }
    }

    private mergeSimilarMeshes(model: Object3D): Object3D {
        const geometriesByMaterial = new Map<Material, BufferGeometry[]>();

        model.traverse((child) => {
            if (child instanceof Mesh && child.geometry) {
                const material = child.material as Material;
                if (!geometriesByMaterial.has(material)) {
                    geometriesByMaterial.set(material, []);
                }

                const clonedGeometry = child.geometry.clone();
                clonedGeometry.applyMatrix4(child.matrixWorld);
                geometriesByMaterial.get(material).push(clonedGeometry);
            }
        });

        const newModel = new Object3D();

        geometriesByMaterial.forEach((geometries, material) => {
            if (geometries.length > 1) {
                try {
                    const mergedGeometry = mergeBufferGeometries(geometries);
                    const mergedMesh = new Mesh(mergedGeometry, material);
                    newModel.add(mergedMesh);

                    geometries.forEach((g) => g.dispose());
                } catch (error) {
                    console.warn('Failed to merge geometries:', error);
                }
            } else if (geometries.length === 1) {
                newModel.add(new Mesh(geometries[0], material));
            }
        });

        return newModel;
    }

    private setupLODLevels(model: Object3D): Object3D {
        const lod = new LOD();

        lod.addLevel(model.clone(), 0);

        const mediumDetail = model.clone();

        //@ts-ignore
        this.optimizeModel(mediumDetail, {
            targetTriangles: this.countTriangles(model) * 0.5,
            preserveTextures: true,
            setupLOD: false,
        });
        lod.addLevel(mediumDetail, 5);

        const lowDetail = model.clone();
        //@ts-ignore
        this.optimizeModel(lowDetail, {
            targetTriangles: this.countTriangles(model) * 0.25,
            preserveTextures: false,
            setupLOD: false,
        });
        lod.addLevel(lowDetail, 15);

        return lod;
    }

    private countTriangles(model: Object3D): number {
        let count = 0;
        model.traverse((child) => {
            if (child instanceof Mesh) {
                const geometry = child.geometry;
                if (geometry.index) {
                    count += geometry.index.count / 3;
                } else {
                    count += geometry.attributes.position.count / 3;
                }
            }
        });
        return count;
    }

    private optimizeMaterial(material: MeshStandardMaterial, options: any): void {
        if (material.map && options.maxTextureSize) {
            if (
                material.map.image &&
                (material.map.image.width > options.maxTextureSize ||
                    material.map.image.height > options.maxTextureSize)
            ) {
                this.resizeTexture(material.map, options.maxTextureSize);
            }
        }

        material.precision = 'lowp';
        material.flatShading = true;
    }

    private preserveUVs(geometry: BufferGeometry, originalUVs: ArrayLike<number>): void {
        if (geometry.attributes.position.count === originalUVs.length / 2) {
            geometry.setAttribute('uv', geometry.attributes.uv);
        }
    }

    private preserveNormals(geometry: BufferGeometry, originalNormals: ArrayLike<number>): void {
        if (geometry.attributes.position.count === originalNormals.length / 3) {
            geometry.setAttribute('normal', geometry.attributes.normal);
        }
    }

    private resizeTexture(texture: Texture, maxSize: number): void {
        if (!texture.image) return;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const ratio = Math.min(maxSize / texture.image.width, maxSize / texture.image.height);

        canvas.width = texture.image.width * ratio;
        canvas.height = texture.image.height * ratio;

        ctx.drawImage(texture.image, 0, 0, canvas.width, canvas.height);
        texture.image = canvas;
        texture.needsUpdate = true;
    }
}
