import React from 'react';
import { container } from 'tsyringe';
import Renderer from '../../Components/Renderer';
import TranslationService from '../../Translations/TranslationService';

export const VrButton = () => {
    const renderer = container.resolve(Renderer);
    const translationService = container.resolve(TranslationService);

    const sessionInit = {
        optionalFeatures: [
            'local-floor',
            'bounded-floor',
            'hand-tracking',
            'layers',
        ],
    };
    const handleClick = async () => {
        if ('xr' in navigator) {
            try {
                const isXrSupported = await navigator.xr.isSessionSupported(
                    'immersive-vr',
                );
                if (isXrSupported) {
                    const session = await navigator.xr.requestSession(
                        'immersive-vr',
                        sessionInit,
                    );

                    //@ts-ignore
                    await renderer.webGLRenderer.xr.setSession(session);
                } else {
                    console.warn('WebXR is not supported on this device');
                }
            } catch (error) {
                console.error('WebXR session failed to start:', error);
            }
        } else {
            console.warn('WebXR is not available in this browser.');
        }
    };
    return (
        <button onClick={handleClick}>
            <span>{translationService.l('vr.menu_enter_vr')}</span>
        </button>
    );
};
