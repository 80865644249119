import VrObject3D from '../Three/VrObject3D';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../Renderer';
import {
    BufferGeometry,
    Clock,
    Color,
    EventDispatcher,
    Group,
    Material,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Raycaster,
    RingGeometry,
    Sprite,
} from 'three';

import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { XRHandModelFactory } from 'three/examples/jsm/webxr/XRHandModelFactory';

import Camera from '../../Camera';
import { pointer as hoverRing } from '../Player/assets/components';
import IntersectionContainer from './IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import linesHelper, { pointer } from './ControllersUtils';
import { Block } from 'three-mesh-ui';
import Time from '../../Utils/Time';
import { MovementService } from './MovementService';
import Config from '../../../Config';

/*
 * XRController Gamepad buttons mapping
 * https://www.w3.org/TR/webxr-gamepads-module-1/
 * */
export const GAMEPAD_BUTTONS = {
    0: '0_BUTTON_PRESSED',
    1: '1_BUTTON_PRESSED',
    2: '2_BUTTON_PRESSED',
    3: '3_BUTTON_PRESSED',
    4: '4_BUTTON_PRESSED',
    5: '5_BUTTON_PRESSED',
};

export const GAMEPAD_BUTTONS_ENB = {
    0: '0_BUTTON_END',
    1: '1_BUTTON_END',
    2: '2_BUTTON_END',
    3: '3_BUTTON_END',
    4: '4_BUTTON_END',
    5: '5_BUTTON_END',
};

export class VrGamepad extends EventDispatcher {
    private readonly gamepad: any;

    private previousButtonStates = [];

    public static BUTTON_END_B = '5_BUTTON_END';
    public static BUTTON_END_A = '4_BUTTON_END';

    public static BUTTON_END_Y = '5_BUTTON_END';
    public static BUTTON_END_X = '4_BUTTON_END';

    public constructor(gamepad: any) {
        super();

        this.gamepad = gamepad;
    }

    public pulse(): void {
        if (this.gamepad === null) {
            return;
        }

        //@ts-ignore
        if (this.gamepad.hapticActuators && this.gamepad.hapticActuators[0]) {
            //@ts-ignore
            this.gamepad.hapticActuators[0].pulse(0.8, 100);
        }
    }

    public update(): void {
        if (this.gamepad === null) {
            return;
        }

        this.updateButtons();
        this.updateAxes();
    }

    public updateAxes(): void {
        if (
            this.gamepad.axes[2] > 0 ||
            this.gamepad.axes[2] < 0 ||
            this.gamepad.axes[3] > 0 ||
            this.gamepad.axes[3] < 0
        ) {
            this.dispatchEvent({
                type: 'axes',
                x: this.gamepad.axes[2],
                y: this.gamepad.axes[3],
            });
        }
    }

    public updateButtons(): void {
        this.gamepad.buttons.forEach((button, index) => {
            const buttonState = button.pressed;

            if (button.pressed === true) {
                if (button.value === 1) {
                    this.dispatchEvent({
                        type: `${index}_BUTTON_PRESSED`,
                        button: button,
                    });
                }
            }

            if (this.previousButtonStates[index] && !buttonState) {
                this.dispatchEvent({
                    type: `${index}_BUTTON_END`,
                    button: button,
                });
            }

            if (!this.previousButtonStates[index] && buttonState) {
                this.dispatchEvent({
                    type: `${index}_BUTTON_START`,
                    button: button,
                });
            }

            this.previousButtonStates[index] = buttonState;
        });
    }
}

@singleton()
@autoInjectable()
export default class XrControllers extends VrObject3D {
    public controllerLeft: Group;
    public controllerRight: Group;

    public controllerGripLeft: Group;
    public controllerGripRight: Group;

    public pointer: Sprite = pointer;
    public intersection: any;
    public controllerType: 'tracked-pointer' | 'gaze' | null = null;

    public hands = [];

    public line: Mesh;

    public constructor(
        public renderer?: Renderer,
        public camera?: Camera,
        public colyseusClient?: ColyseusClient,
        private movementService?: MovementService,
        private config?: Config,
    ) {
        super();
        this.setupControllers();
        const controllerModelFactory = new XRControllerModelFactory();

        this.controllerLeft.addEventListener('connected', (event) => {
            this.controllerLeft.userData.gamepad = new VrGamepad(
                event.data.gamepad,
            );

            this.addGamepadButtonReleaseListeners(this.controllerLeft);

            this.controllerGripLeft.add(
                controllerModelFactory.createControllerModel(
                    this.controllerGripLeft,
                ),
            );
        });
        this.controllerRight.addEventListener('connected', (event) => {
            this.controllerType = event.data.targetRayMode;

            this.controllerGripRight.add(
                controllerModelFactory.createControllerModel(
                    this.controllerGripRight,
                ),
            );
        });
    }

    private addGamepadButtonReleaseListeners(controller: any) {
        for (const button in GAMEPAD_BUTTONS_ENB) {
            controller.userData.gamepad.addEventListener(
                `${button}_BUTTON_END`,
                (data) => {
                    controller.dispatchEvent({
                        type: `${button}_BUTTON_END`,
                        data,
                    });
                },
            );
        }
    }

    private setupControllers() {
        if (this.movementService.initialSettings.leftHanded) {
            this.controllerLeft =
                this.renderer.webGLRenderer.xr.getController(1);
            this.controllerRight =
                this.renderer.webGLRenderer.xr.getController(0);
            this.controllerGripLeft =
                this.renderer.webGLRenderer.xr.getControllerGrip(1);
            this.controllerGripRight =
                this.renderer.webGLRenderer.xr.getControllerGrip(0);
        } else {
            this.controllerLeft =
                this.renderer.webGLRenderer.xr.getController(0);
            this.controllerRight =
                this.renderer.webGLRenderer.xr.getController(1);
            this.controllerGripLeft =
                this.renderer.webGLRenderer.xr.getControllerGrip(0);
            this.controllerGripRight =
                this.renderer.webGLRenderer.xr.getControllerGrip(1);
        }
    }

    public setLine(): void {
        if (this.config.skipLegacyIntersection) {
            return;
        }

        this.line = linesHelper;
        this.line.name = 'line';
        this.line.visible = true;
        this.line.renderOrder = Infinity;
        this.line.position.y = -0.01;
    }

    public hapticResponse(controller: any) {
        if (
            controller.userData.gamepad === null ||
            this.controllerRight.userData.gamepad === undefined
        ) {
            return;
        }
        if (controller.userData.gamepad === undefined) {
            return;
        }

        controller.userData.gamepad.pulse();
    }

    public createHands() {
        const handModelFactory = new XRHandModelFactory();

        this.hands.push(this.renderer.webGLRenderer.xr.getHand(0));
        this.hands[0].add(
            //@ts-ignore
            handModelFactory.createHandModel(this.hands[0], 'mesh'),
        );

        this.hands.push(this.renderer.webGLRenderer.xr.getHand(1));
        this.hands[1].add(
            //@ts-ignore
            handModelFactory.createHandModel(this.hands[1], 'mesh'),
        );
    }

    public init() {
        this.createHands();

    }

    public xrSessionStart(event) {
        // this.pointer.visible = true;
        this.camera?.cameraGroup.add(this.controllerGripLeft);
        this.camera?.cameraGroup.add(this.controllerGripRight);
        this.camera?.cameraGroup.add(this.controllerLeft);
        this.camera?.cameraGroup.add(this.controllerRight);
        this.camera?.cameraGroup.add(this.hands[0]);
        this.camera?.cameraGroup.add(this.hands[1]);
    }

    public buttonPressed(event) {}

    public xrSessionEnd(_event: any): void {
        this.camera.cameraGroup.remove(this.controllerGripLeft);
        this.camera.cameraGroup.remove(this.controllerGripRight);
    }

    public update() {
        if (this.controllerLeft.userData.gamepad !== undefined) {
            this.controllerLeft.userData.gamepad.update();
        }

        if (this.controllerRight.userData.gamepad !== undefined) {
            this.controllerRight.userData.gamepad.update();
        }
    }

    public async xrUpdate() {
        Time.limitFrames(async () => {
            if (this.colyseusClient.isMultiplayer && this.colyseusClient.room) {
                if (
                    this.controllerType === 'tracked-pointer' &&
                    this.colyseusClient.room
                ) {
                    await this.colyseusClient.updateController(
                        this.controllerGripLeft.position,
                    );
                    await this.colyseusClient.updateControllerRotation(
                        this.controllerGripLeft.quaternion,
                    );

                    await this.colyseusClient.updateRightController(
                        this.controllerGripRight.position,
                    );
                    await this.colyseusClient.updateControllerSecondRotation(
                        this.controllerGripRight.quaternion,
                    );
                }
            }
        }, 24);
    }
}

export class GazeRing extends Mesh {
    public material: Material | Material[] = new MeshBasicMaterial({
        opacity: 0.5,
        transparent: true,
    });

    public geometry: BufferGeometry = new RingGeometry(
        0.02,
        0.04,
        32,
    ).translate(0, 0, -1);

    public constructor() {
        super();
    }

    public setDefault() {
        this.geometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
    }
}
