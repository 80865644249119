/* eslint-disable indent */
import { autoInjectable } from 'tsyringe';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import VrObject3D from '../Three/VrObject3D';
import { Block, Text } from 'three-mesh-ui';

//@ts-ignore
import FontJSON from '../../../../static/assets/fonts/Roboto-Regular-msdf.json';
//@ts-ignore
import FontImage from '../../../../static/assets/fonts/Roboto-Regular.png';

import Camera from '../../Camera';
import Time from '../../Utils/Time';
import Resources from '../../Resources';
import { Color, RepeatWrapping, TextureLoader } from 'three';
import Controlls from '../Controllers/Controlls';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import IconScrollUpWhite from '../../../../static/assets/icons/icon-name-scroll-up-white-full.png';
import IconScrollDownWhite from '../../../../static/assets/icons/icon-name-scroll-down-white-full.png';
import Utils from '../../Utils/Utils';
import defaultCourseIcon from '../../../../static/assets/icons/defaultCourseIcon.png';
import { MaskLoader } from './MaskLoader';
import IMyContent from '../../Network/Models/My_content/IMyContent';
import { AxiosResponse } from 'axios';
import { Coursebook } from './Coursebook';

export const hoveredStateAttributes = {
    state: 'hovered',
    attributes: {
        offset: 0.035,
        backgroundColor: new Color(0x1847c9),
        backgroundOpacity: 1,
        fontColor: new Color(0xffffff),
    },
};

export const idleStateAttributes = {
    state: 'idle',
    attributes: {
        backgroundColor: new Color('black'),
    },
};

export const clickStateAttributes = {
    state: 'selected',
    attributes: {
        backgroundOpacity: 1,
        backgroundColor: new Color('lightblue'),
        offset: 0.06,
    },
};

@autoInjectable()
export class LessonView extends VrObject3D {
    public container: Block;
    public courses: AxiosResponse<IMyContent[]>;

    public mask: boolean = true;
    public containerMask: MaskLoader;

    public constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public controls?: Controlls,
    ) {
        super();

        this.fetchCourses();
    }

    public disableShadows() {}

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.001,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    public async fetchCourses() {
        if (this.mask) {
            const containerMask = new MaskLoader();
            containerMask.position.z = 0.15;
            this.add(containerMask);
            this.containerMask = containerMask;
        }

        try {
            this.courses = await this.httpClient.getCourses();
            this.mask = false;
        } catch (err) {
            console.log('Error w pobieraniu danych');
        }

        if (!this.mask) {
            this.containerMask.removeMask();
        }

        let borderWidth = 0.01;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.8;
        let offsetActiveObj = 0.03;
        // let offsetActiveObj = 0.07;

        if (this.courses.data.length > 0) {
            //MAIN CONTAINER
            this.container = new Block({
                width: 4.2,
                // width: 8,
                // height: 4.5,
                height: 2.5,
                opacity: 1,
                fontFamily: FontJSON,
                fontTexture: FontImage,
                hiddenOverflow: true,
                backgroundOpacity: 0,
                borderRadius: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: 0,
                // padding: 0.05,
            });

            this.container.userData.type = 'ui-board';
            this.container.userData.id = 'ui-board';
            this.intersectionContainer.addObjectToIntersect(
                this.container,
                true,
                false,
            );
            this.setDefaultState(this.container);

            // wrapper for vertical scroll
            const containerWrapper = new Block({
                // width: 7.8,
                width: 4.2,
                height: 2.5,
                // height: 4.3,
                contentDirection: 'row',
                alignItems: 'start',
                hiddenOverflow: true,
                backgroundOpacity: 0,
                borderRadius: 0,
                borderWidth: 0,
                borderOpacity: 0,
            });
            this.container.add(containerWrapper);

            const mainContent = new Block({
                // width: 7.6,
                // height: 4.3,
                width: 4,
                height: 2.5,
                backgroundOpacity: 0,
                borderWidth: 0,
                offset: 0.0001, //
            });

            const verticalScrollWrapper = new Block({
                width: 0.2,
                // height: 4.3,
                height: 2.5,
                contentDirection: 'column',
                alignItems: 'right',
                backgroundOpacity: 0,
                borderWidth: 0,
                offset: 0.0001, //
            });

            containerWrapper.add(mainContent, verticalScrollWrapper);

            // btns for verticalScrollWrapper
            const btnScrollOptions = {
                width: 0.1,
                height: 0.1,
                offset: offsetActiveObj,
                backgroundOpacity: 0,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
            };

            const btnScrollUp = new Block(btnScrollOptions);
            const btnScrollDown = new Block(btnScrollOptions);

            // IconScrollUpWhite
            new TextureLoader().load(IconScrollUpWhite, (texture) => {
                btnScrollUp.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });
            // IconScrollDownWhite
            new TextureLoader().load(IconScrollDownWhite, (texture) => {
                btnScrollDown.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });

            const panelScrollSpace = new Block({
                width: 0.2,
                // height: 4.1,
                height: 2.3,
                backgroundOpacity: 0,
                borderWidth: 0,
                borderOpacity: 0,
            });

            verticalScrollWrapper.add(
                btnScrollUp,
                panelScrollSpace,
                btnScrollDown,
            );

            this.setDefaultState(btnScrollUp, () => {
                // @ts-ignore
                mainContent.position.y === 0
                    ? (mainContent.position.y += 0)
                    : (mainContent.position.y -= 0.2);
            });
            this.setDefaultState(btnScrollDown, () => {
                //@ts-ignore
                mainContent.position.y >= mainContent.height
                    ? (mainContent.position.y += 0)
                    : (mainContent.position.y += 0.2);
            });

            this.intersectionContainer.addObjectToIntersect(
                btnScrollUp,
                false,
                true,
            );
            this.intersectionContainer.addObjectToIntersect(
                btnScrollDown,
                false,
                true,
            );

            //--

            //sortowanie alfabetyczne
            //@ts-ignore
            this.courses.data.sort(function (obj1, obj2) {
                if (obj2.title > obj1.title) return -1;
                else if (obj2.title < obj1.title) {
                    return 1;
                } else {
                    return 0;
                }
            });

            this.courses.data.forEach((course) => {
                //blok z kursem
                const courseBlock = new Block({
                    // width: 7.6,
                    width: 4,
                    height: 0.3,
                    contentDirection: 'row',
                    backgroundOpacity: 0,
                    hiddenOverflow: true,
                    borderRadius: 0,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                const courseCover = new Block({
                    width: 0.6,
                    height: 0.3,
                    justifyContent: 'center',
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                });

                new TextureLoader().load(defaultCourseIcon, (texture) => {
                    courseCover.add(
                        new Block({
                            width: 0.2,
                            height: 0.26,
                            backgroundTexture: texture,
                        }),
                    );
                });

                const courseTitle = new Block({
                    // width: 7,
                    width: 3.2,
                    height: 0.3,
                    fontSize: 0.08,
                    textAlign: 'left',
                    justifyContent: 'center',
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    borderRadius: 0,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                });

                const courseTitleText = new Text({
                    content:
                        course.title === 'api.courses.unassigned_courses'
                            ? 'Unassigned CoursesPanel'
                            : Utils.stripNonAplhaNumericAndHtml(course.title),
                });

                courseTitle.add(courseTitleText);

                const space = new Block({
                    // width: 7.6,
                    width: 4,
                    height: 0.02,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                courseBlock.add(courseCover, courseTitle);

                this.intersectionContainer.addObjectToIntersect(
                    courseBlock,
                    false,
                    true,
                );
                this.setDefaultState(courseBlock, () => {
                    let courseBook = {
                        coursebookSet: course.course_set,
                        coursebookId: course.id,
                        courseTitle: course.title,
                    };

                    // @ts-ignore
                    const coursebookView = new Coursebook(courseBook);

                    coursebookView.position.z = offsetActiveObj + 0.01;

                    // @ts-ignore
                    this.add(coursebookView);
                });

                mainContent.add(courseBlock, space);
            });
        }

        this.add(this.container);

        this.container.traverse((block) => {
            if (block.name === 'MeshUI-Frame') {
                block.castShadow = false;
                block.receiveShadow = false;
            }
        });
    }
}

@autoInjectable()
export default class DockView extends VrObject3D {
    public container: Block;

    public constructor(
        public camera?: Camera,
        public resources?: Resources,
        public controls?: Controlls,
        public intersectionContainer?: IntersectionContainer,
    ) {
        super();

        const verticalAttributes = {
            state: 'vertical',
            attributes: {
                width: 1,
                height: 2.5,
                contentDirection: 'column',
                justifyContent: 'space-between',
            },
        };

        const horizontalAttributes = {
            state: 'horizontal',
            attributes: {
                width: 5,
                height: 0.5,
                justifyContent: 'space-between',
                contentDirection: 'row',
            },
        };

        this.container = new Block({
            width: 5,
            height: 0.5,
            borderRadius: 0,
            contentDirection: 'row',
            backgroundOpacity: 0.9,
            padding: 0.2,
        });

        //@ts-ignore
        this.container.setupState(horizontalAttributes);

        //@ts-ignore
        this.container.setupState(verticalAttributes);

        this.add(this.container);
    }

    public update(_time?: Time): void {}
}
