import 'reflect-metadata';
import 'bootstrap/dist/css/bootstrap.css';
import './style/main.css';
import Bootstrap from './Bootstrap';
import envierments from './Environments/envierments';

const bootstrap = new Bootstrap();

console.log(envierments.baseURL);
console.log(envierments.janus);
console.log(envierments.colyseus);
console.log(envierments.colyseusHttp);
