import VrObject3D from '../Three/VrObject3D';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Controlls from '../Controllers/Controlls';

import { Color, TextureLoader } from 'three';
import { Block, Text } from 'three-mesh-ui';

//@ts-ignore
import FontJSON from '../../../../static/assets/fonts/Roboto-Regular-msdf.json';
//@ts-ignore
import FontImage from '../../../../static/assets/fonts/Roboto-Regular.png';

import { autoInjectable } from 'tsyringe';
import Camera from '../../Camera';
import Resources from '../../Resources';
import Time from '../../Utils/Time';

//icon
import IconCalendarWhite from '../../../../static/assets/icons/icon-name-calendar-white.png';
import IconMessangerWhite from '../../../../static/assets/icons/icon-name-messanger-white.png';
import IconNotificationWhite from '../../../../static/assets/icons/icon-name-notification-white.png';
import IconArrowRightWhite from '../../../../static/assets/icons/icon-name-arrow-right-white.png';
import IconScrollLeftWhite from '../../../../static/assets/icons/icon-name-scroll-left-white-full.png';
import IconScrollRightWhite from '../../../../static/assets/icons/icon-name-scroll-right-white-full.png';
import IconPlusWhite from '../../../../static/assets/icons/icon-name-plus-white.png';
import { Rooms } from '../Three/VrScene';

export const hoveredStateAttributes = {
    state: 'hovered',
    attributes: {
        backgroundColor: new Color(0xffffff),
        backgroundOpacity: 0.1,
    },
};

export const idleStateAttributes = {
    state: 'idle',
    attributes: {
        backgroundOpacity: 0.001,
    },
};

export const clickStateAttributes = {
    state: 'selected',
    attributes: {
        backgroundColor: new Color(0xffffff),
        backgroundOpacity: 0.1,
    },
};

@autoInjectable()
export class PersonalMenuHorizontal extends VrObject3D {
    public container: Block;
    public submenuContainer: Block;

    public classes: any;
    public groups: any;
    public users: any;

    public myClassesAndSubjectList = [];

    private panelVisibility: boolean = false;
    private visiblePanelName: string = '';

    private objToRemoveIntersection: (string | number)[] = [];

    constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public camera?: Camera,
        public controls?: Controlls,
        public resources?: Resources,
    ) {
        super();

        //-------------------
        // panel orientation
        const verticalAttributes = {
            state: 'vertical',
            attributes: {
                width: 1,
                height: 2.5,
            },
        };

        const horizontalAttributes = {
            state: 'horizontal',
            attributes: {
                width: 5,
                height: 0.5,
            },
        };

        // this.init();

        let _container = this.createPersonalMenu();
        this.container = _container;
        this.renderOrder = Infinity;

        this.add(_container);

        // @ts-ignore
        this.container.setupState(horizontalAttributes);
        // @ts-ignore
        this.container.setupState(verticalAttributes);
    }

    public removeObject(obj) {
        let allChild = [];

        obj.traverse(function (child) {
            if (child instanceof Block || VrObject3D) {
                allChild.push(child);
            }
        });

        let all = this.intersectionContainer.objectsToIntersect;
        all = all.filter(
            (one) => !allChild.find((two) => one.uuid == two.uuid),
        );
        this.intersectionContainer.objectsToIntersect = all;

        let parent = obj.parent;
        parent.remove(obj);
    }

    toggleVisibilitySubmenuPanel(panelName: string) {
        // Remove panel if it is already open
        if (this.visiblePanelName === panelName) {
            this.panelVisibility = false;
            this.submenuContainer.visible = false;
            this.visiblePanelName = '';
            let _objToRemove = this.submenuContainer.getObjectByName(panelName);
            this.removeObject(_objToRemove);
        } else {
            //If another panel is open, remove it and open a new panel
            if (this.visiblePanelName.length > 0) {
                this.panelVisibility = false;
                this.submenuContainer.visible = false;
                let _objToRemove = this.submenuContainer.getObjectByName(
                    this.visiblePanelName,
                );
                this.removeObject(_objToRemove);

                this.visiblePanelName = panelName;
                this.getData(panelName);
                this.panelVisibility = true;
                this.submenuContainer.visible = true;
            } else {
                //Open a panel if none was open
                this.visiblePanelName = panelName;
                this.getData(panelName);
                this.panelVisibility = true;
                this.submenuContainer.visible = true;
            }
        }
    }

    createPersonalMenu() {
        let borderWidth = 0.001;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.5;
        let offsetActiveObj = 0.05;

        const container = new Block({
            width: 5,
            height: 0.5,
            borderRadius: 0,
            backgroundOpacity: 0,
            contentDirection: 'column',
            justifyContent: 'end',
        });

        container.position.set(0, 5, 0);

        // widok 2
        const personalSubmenuWrapper = new Block({
            width: 5,
            height: 0.5,
            borderRadius: 0,
            padding: 0.2,
            offset: 0.01,
            backgroundColor: new Color(0xffffff),
            backgroundOpacity: 0.2,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });

        container.add(personalSubmenuWrapper);
        this.submenuContainer = personalSubmenuWrapper;
        this.submenuContainer.visible = this.panelVisibility;

        // widok 1
        const personalMenuWrapper = new Block({
            width: 5,
            height: 0.5,
            borderRadius: 0,
            backgroundColor: new Color(0xffffff),
            backgroundOpacity: 0.1,
            padding: 0.2,
            contentDirection: 'row',
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });

        container.add(personalMenuWrapper);

        this.intersectionContainer.addObjectToIntersect(
            personalMenuWrapper,
            true,
            true,
        );
        this.setDefaultState(personalMenuWrapper);

        //-----

        // horizontal wrapper with 3 btn
        const btnWrapper = new Block({
            width: 0.8,
            height: 0.3,
            margin: 0.01,
            offset: 0.05,
            backgroundOpacity: 0,
            borderWidth: 0,
            contentDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        });

        btnWrapper.add(
            this.createBtnWithoutText(IconCalendarWhite, 'calendar'),
        );
        btnWrapper.add(
            this.createBtnWithoutText(IconMessangerWhite, 'messanger'),
        );
        btnWrapper.add(
            this.createBtnWithoutText(IconNotificationWhite, 'notification'),
        );

        personalMenuWrapper.add(btnWrapper);
        //------------------------------------------

        //Other btns
        //LOBBY
        personalMenuWrapper.add(this.createBtn('LOBBY'));

        //CLASS
        personalMenuWrapper.add(
            this.createBtn('CLASS', IconArrowRightWhite, (btn) => {
                this.toggleVisibilitySubmenuPanel(btn.name);
            }),
        );

        //GROUP
        personalMenuWrapper.add(
            this.createBtn('GROUP', IconArrowRightWhite, (btn) => {
                this.toggleVisibilitySubmenuPanel(btn.name);
            }),
        );

        //FRIEND
        personalMenuWrapper.add(
            this.createBtn('FRIEND', IconArrowRightWhite, (btn) => {
                this.toggleVisibilitySubmenuPanel(btn.name);
            }),
        );

        //PRIVATE ROOM
        personalMenuWrapper.add(this.createBtn('PRIVATE ROOM', null));

        //EXIT VR
        personalMenuWrapper.add(this.createBtn('EXIT VR', null));

        return container;
    }

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.001,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    public async getData(dataName: string) {
        //##############################################################################################################
        // TEMP

        let tempGroup = {
            data: [
                {
                    id: Rooms.GroupRoom,
                    name: 'Group A',
                    students_count: 2,
                    code: '5F39BHSZ',
                    teacher: 5203687618641920,
                    additional_teachers: '[5203687618641920]',
                },
            ],
        };
        this.groups = tempGroup;

        // TEMP
        let tempUser = {
            data: {
                students: [
                    {
                        id: 4723940039589888,
                        username: 'Dylan Chase',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                    {
                        id: 5568364969721856,
                        username: 'Katy Conley',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        classes: [],
                        subjects: [],
                    },
                    {
                        id: 5146152504655872,
                        username: 'Allie Evans',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                    {
                        id: 5146152504655873,
                        username: 'Donna Fuller',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                    {
                        id: 5146152504655874,
                        username: 'Jack Morris',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                    {
                        id: 5146152504655875,
                        username: 'Hana Solo',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                    {
                        id: 5146152504655875,
                        username: 'Jim Young',
                        first_name: '',
                        last_name: '',
                        photo_url: '/media/img/no_avatar.png',
                        school_classes: [],
                        subjects: [],
                    },
                ],
                more_count: 0,
                cursor: null,
            },
        };
        this.users = tempUser;
        //##############################################################################################################

        //check data name for API
        let data;

        if (dataName) {
            if (dataName === 'class') {
                // console.log('pobierz dla klasy')

                //TEMP
                const userType = await this.httpClient.getUser();

                if (userType) {
                    //user student
                    if (userType.data.is_school_student) {
                        //   get classes for user
                        this.classes =
                            await this.httpClient.getClassesForStudent();
                        if (
                            this.classes &&
                            this.classes.hasOwnProperty('data') &&
                            this.classes.data.length > 0
                        ) {
                            this.classes.data.forEach((className) => {
                                if (
                                    className.hasOwnProperty('subjects') &&
                                    className.subjects.length > 0
                                ) {
                                    for (
                                        let i = 0;
                                        i < className.subjects.length;
                                        i++
                                    ) {
                                        this.myClassesAndSubjectList.push({
                                            className: className.name,
                                            classId: className.id,
                                            subjectName:
                                                className.subjects[i].name,
                                            subjectId: className.subjects[i].id,
                                        });
                                    }
                                }
                            });
                        }

                        //user teacher
                    } else if (userType.data.is_school_teacher) {
                        // get classes for teacher
                        this.classes =
                            await this.httpClient.getClassesForTeacher();

                        if (
                            this.classes &&
                            this.classes.hasOwnProperty('data') &&
                            this.classes.data.length > 0
                        ) {
                            this.classes.data.forEach((className) => {
                                this.myClassesAndSubjectList.push({
                                    className: className.school_class_name,
                                    classId: className.class_id,
                                    subjectName: className.name,
                                    subjectId: className.id,
                                });
                            });
                        }
                    }
                }

                data = this.myClassesAndSubjectList;
            }

            if (dataName === 'group') {
                // console.log('pobierz dla grupy')
                //todo: API
                // data = await this.httpClient.getGroups();
                //    /api/v2/connections/student/groups     ? puste
                //    /api/v2/connections/my_private_classes    ? ze strony

                data = this.groups.data;
            }

            if (dataName === 'friend') {
                // console.log('pobierz dla userów');

                //todo: API
                // data = await this.httpClient.getUser();

                // data = this.users;
                this.users.data.hasOwnProperty('students') &&
                this.users.data['students'].length > 0
                    ? (data = this.users.data['students'])
                    : console.log('ERROR - no data');
            }

            //

            let borderWidth = 0.001;
            let borderColor = new Color(0xffffff);
            let borderOpacity = 0.5;
            let offsetActiveObj = 0.05;

            const dataContainer = new Block({
                width: 5,
                height: 0.4,
                borderRadius: 0,
                backgroundOpacity: 0,
                backgroundColor: new Color(0x4b4d4d),
                fontFamily: FontJSON,
                fontTexture: FontImage,
                fontColor: new Color(0xffffff),
                alignItems: 'start',
                borderWidth: 0,
            });

            dataContainer.name = dataName;
            this.intersectionContainer.addObjectToIntersect(
                dataContainer,
                true,
                false,
            );
            this.setDefaultState(dataContainer);

            //panel title
            const containerTitle = new Block({
                width: 4.8,
                height: 0.1,
                justifyContent: 'center',
                textAlign: 'left',
                fontSize: 0.08,
                backgroundOpacity: 0,
                padding: 0.1,
                borderWidth: 0,
            });
            containerTitle.add(
                new Text({
                    content:
                        dataName === 'friend'
                            ? 'Invite friends:'
                            : dataName === 'class'
                            ? 'Go to class:'
                            : 'Go to group:',
                }),
            );
            dataContainer.add(containerTitle);

            //wrapper to scroll
            const contentWrapper = new Block({
                width: 5,
                height: 0.4,
                backgroundOpacity: 0,
                hiddenOverflow: true,
                contentDirection: 'column',
                borderWidth: 0,
            });

            const mainContent = new Block({
                width: 5,
                height: 0.3,
                backgroundOpacity: 0,
                contentDirection: 'row',
                borderWidth: 0,
            });

            const btnScrollWrapper = new Block({
                width: 5,
                height: 0.1,
                backgroundOpacity: 0,
                contentDirection: 'row',
                justifyContent: 'space-between',
                borderWidth: 0,
            });
            const btnScrollOptions = {
                width: 0.3,
                height: 0.1,
                offset: 0.1,
                backgroundOpacity: 0,
                justifyContent: 'center',
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
            };

            const btnScrollLeft = new Block(btnScrollOptions);
            const btnScrollRight = new Block(btnScrollOptions);
            btnScrollLeft.name = 'btnScrollLeft';
            btnScrollRight.name = 'btnScrollDown';

            // IconScrollLeftWhite  -Up
            new TextureLoader().load(IconScrollLeftWhite, (texture) => {
                btnScrollLeft.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });

            // IconScrollRightWhite - Down
            new TextureLoader().load(IconScrollRightWhite, (texture) => {
                btnScrollRight.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });

            dataContainer.add(contentWrapper);
            contentWrapper.add(mainContent, btnScrollWrapper);
            btnScrollWrapper.add(btnScrollLeft, btnScrollRight);

            this.setDefaultState(btnScrollLeft, () => {
                // @ts-ignore

                //@ts-ignore
                let _maxScroll = 0;
                //@ts-ignore
                for (let i = 0; i < mainContent.childrenBoxes.length; i++) {
                    //@ts-ignore
                    _maxScroll += mainContent.childrenBoxes[i].width;
                }
                //@ts-ignore
                mainContent.width > _maxScroll
                    ? (mainContent.position.x += 0)
                    : mainContent.position.x * -1 >= _maxScroll
                    ? (mainContent.position.x += 0)
                    : (mainContent.position.x -= 0.1);
            });
            this.setDefaultState(btnScrollRight, () => {
                //@ts-ignore

                //@ts-ignore
                let _maxScroll = 0;
                //@ts-ignore
                for (let i = 0; i < mainContent.childrenBoxes.length; i++) {
                    //@ts-ignore
                    _maxScroll += mainContent.childrenBoxes[i].width;
                }
                //@ts-ignore
                _maxScroll < mainContent.width
                    ? (mainContent.position.x += 0)
                    : //@ts-ignore
                    mainContent.width > _maxScroll
                    ? (mainContent.position.x += 0)
                    : mainContent.position.x < 0
                    ? (mainContent.position.x += 0.1)
                    : (mainContent.position.x += 0);
            });

            this.intersectionContainer.addObjectToIntersect(
                btnScrollLeft,
                false,
                false,
            );
            this.intersectionContainer.addObjectToIntersect(
                btnScrollRight,
                false,
                false,
            );
            this.objToRemoveIntersection.push(btnScrollLeft.name);
            this.objToRemoveIntersection.push(btnScrollRight.name);

            //panel main content with btn userName/icon
            data.forEach((item) => {
                const btn = new Block({
                    width: 0.75,
                    height: 0.2,
                    margin: 0.01,
                    offset: 0.1,
                    backgroundOpacity: 0,
                    fontColor: new Color(0xffffff),
                    fontSize: 0.08,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    contentDirection: 'row',
                    borderRadius: 0,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                //todo
                btn.name = item.id;

                let textWrapper = new Block({
                    width: 0.6,
                    height: 0.2,
                    justifyContent: 'center',
                    backgroundOpacity: 0,
                    textAlign: 'left',
                    fontSize: 0.08,
                    padding: 0.1,
                    borderWidth: 0,
                });
                btn.add(textWrapper);

                // different content for data (class|group|friend)
                let contentText = '';
                if (dataName === 'class') {
                    contentText = item.className + ' - ' + item.subjectName;
                } else if (dataName === 'friend') {
                    contentText = item.username;
                } else if (dataName === 'group') {
                    contentText = item.name;
                }

                if (contentText.length * 0.04 > 0.6) {
                    //@ts-ignore
                    btn.width = contentText.length * 0.04;
                    //@ts-ignore
                    textWrapper.width = contentText.length * 0.04 - 0.06; //- icon wrapper | todo
                }

                textWrapper.add(
                    new Text({
                        // content: dataName === 'friend' ?  item.username : item.name
                        content: contentText,
                    }),
                );

                let iconWrapper = new Block({
                    width: 0.06,
                    height: 0.06,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                });

                new TextureLoader().load(IconPlusWhite, (texture) => {
                    iconWrapper.add(
                        new Block({
                            width: 0.06,
                            height: 0.06,
                            backgroundTexture: texture,
                            borderWidth: 0,
                        }),
                    );
                });

                btn.add(iconWrapper);
                mainContent.add(btn);

                this.intersectionContainer.addObjectToIntersect(
                    btn,
                    false,
                    false,
                );
                // btn.name = element id from data
                this.objToRemoveIntersection.push(btn.name);

                this.setDefaultState(btn, () => {
                    // btn.name = element id from data
                    let btnId = btn.name;

                    if (dataName === 'class') {
                        // console.log(
                        //     'click ClassId: ' +
                        //         item.classId +
                        //         ' -- subjectId:' +
                        //         item.subjectId +
                        //         ' in panel ' +
                        //         dataName,
                        // );
                    } else {
                        // console.log(
                        //     'click btnId: ' + btnId + ' in panel ' + dataName,
                        // );
                        // console.log('panel-' + dataName + '-id-' + btnId);
                    }

                    this.dispatchEvent({
                        type: '',
                        data: [],
                    });
                });
            });

            this.submenuContainer.add(dataContainer);
        }
    }

    //Function return btn with icon
    createBtnWithoutText(icon: string, btnName: string) {
        let borderWidth = 0.003;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.5;
        let offsetActiveObj = 0.05;

        const btn = new Block({
            width: 0.25,
            height: 0.25,
            padding: 0.02,
            offset: offsetActiveObj,
            backgroundOpacity: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });
        btn.name = btnName;

        new TextureLoader().load(icon, (texture) => {
            btn.add(
                new Block({
                    width: 0.15,
                    height: 0.15,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });

        //@ts-ignore
        btn.setupState(hoveredStateAttributes);
        //@ts-ignore
        btn.setupState(idleStateAttributes);

        const clickAttribute = {
            ...clickStateAttributes,
            onSet: () => {
                // console.log('Click btn: ' + btn.name);
                // console.log('event: btnPersonalMenu-' + btn.name);

                // todo
                this.dispatchEvent({
                    type: 'btnPersonalMenu-' + btn.name,
                });
            },
        };

        //@ts-ignore
        btn.setupState(clickAttribute);

        this.intersectionContainer.addObjectToIntersect(btn, false, true);

        return btn;
    }

    //return btn with text | with or without icon  | additional function
    createBtn(text: string, icon?: string, click?: Function) {
        const btn = new Block({
            width: 0.6, //0.8
            height: 0.25, //0.25
            padding: 0.02,
            margin: 0.01,
            offset: 0.15,
            backgroundOpacity: 0,
            fontColor: new Color(0xffffff),
            fontSize: 0.08,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            contentDirection: 'row',
        });

        btn.userData = {
            dataName: text,
        };

        //text to camelCase
        //@ts-ignore
        btn.name = text
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

        let textWrapper = new Block({
            width: icon ? 0.4 : 0.6,
            height: 0.25,
            justifyContent: 'center',
            textAlign: 'left',
            backgroundOpacity: 0,
            borderWidth: 0,
            padding: 0.01,
        });
        btn.add(textWrapper);

        textWrapper.add(
            new Text({
                content: text,
            }),
        );

        if (icon) {
            let iconWrapper = new Block({
                width: 0.2,
                height: 0.25,
                backgroundOpacity: 0,
                borderWidth: 0,
                justifyContent: 'center',
            });
            btn.add(iconWrapper);

            new TextureLoader().load(icon, (texture) => {
                iconWrapper.add(
                    new Block({
                        width: 0.15,
                        height: 0.15,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });
        }

        //@ts-ignore
        btn.setupState(hoveredStateAttributes);
        //@ts-ignore
        btn.setupState(idleStateAttributes);

        const clickAttribute = {
            ...clickStateAttributes,
            onSet: () => {
                // console.log('Click btn: ' + btn.name);
                // console.log('event: btnPersonalMenu-' + btn.name);

                if (click) {
                    click(btn);
                }

                // todo
                this.dispatchEvent({
                    type: 'btnPersonalMenu-' + btn.name,
                });
            },
        };

        //@ts-ignore
        btn.setupState(clickAttribute);

        this.intersectionContainer.addObjectToIntersect(btn, false, true);

        return btn;
    }

    init() {
        let _container = this.createPersonalMenu();
        this.container = _container;

        this.intersectionContainer.addObjectToIntersect(this.container);
        this.setDefaultState(this.container);

        this.add(_container);
    }

    public update(_time?: Time): void {}
}
