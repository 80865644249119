import {
    Audio as ThreeAudio,
    AudioLoader,
    Color,
    DoubleSide,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    PositionalAudio,
    RingGeometry,
} from 'three';
import VrObject3D from './Three/VrObject3D';
import * as TWEEN from '@tweenjs/tween.js';
import { autoInjectable, container } from 'tsyringe';
import ThreeMeshUI, { Block, Text } from 'three-mesh-ui';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { Text as TriokaText } from 'troika-three-text';
import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';
import IntersectionContainer from './Controllers/IntersectionContainer';
import AxiosHttpClient from '../Network/AxiosHttpClient';
import Camera from '../Camera';
import { VrGamepad } from './Controllers/XrControllers';
import { User } from '../Network/Models/User';
import Controlls from './Controllers/Controlls';
import TranslationService from '../Translations/TranslationService';
import OpenAiClient from './OpenAi/OpenAiClient';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        webkitAudioContext: typeof AudioContext;
    }
}

// const CHAR_ID = 'befabf34-6427-11ed-acbd-42010a80000c'; //charlie
const CHAR_ID = 'ddf108e6-d469-11ed-bc22-42010a7c4003';

@autoInjectable()
export default class ChatBot extends VrObject3D {
    public sessionId: string;

    public isProcessing = false;

    public isRecording = false;

    public stream: MediaStream;

    public mediaRecorder: MediaRecorder;

    public chunks = [];

    public textResponseBlock: Block;

    public textResponse: Text;

    public usernameText: TriokaText;

    public recordingContainer: Block;

    public controller: any;

    public gamepad: VrGamepad;

    public isButtonPressed: string = 'idle';

    public loadingCrircle = new LoadingCrircle();

    public positionalSound = null;

    public audioLoader = new AudioLoader();

    public constructor(
        public intersectionContainer?: IntersectionContainer,
        public httpClient?: AxiosHttpClient,
        public camera?: Camera,
        public user?: User,
        public controlls?: Controlls,
        public translationService?: TranslationService,
    ) {
        super();
        this.init();
        this.setModel();

        this.createButton();
    }

    public async init() {
        try {
            await register(await connect());
        } catch (e) {
            console.info(e);
        }

        this.controller = this.renderer.webGLRenderer.xr.getController(1);
        // this.controller.addEventListener('connected', (event) => {
        //     this.controller.addEventListener('selectstart', () => {
        //         this.startRecording();
        //     });
        //
        //     this.controller.addEventListener('selectend', () => {
        //         this.stopRecoring();
        //     });
        // });

        document.addEventListener('keydown', this.keyDown);
        document.addEventListener('keyup', this.keyUp);

        this.textResponseBlock = new Block({
            width: 1.2,
            height: 2,
            borderRadius: 0.2,
            bestFit: 'auto',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            padding: 0.1,
        });

        this.textResponseBlock.visible = false;

        this.textResponseBlock.position.set(1.2, -0.5, 0);

        this.textResponse = new Text({
            content: '',
        });

        this.textResponseBlock.add(this.textResponse);

        this.stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });

        const audioCtx = new AudioContext();

        const source = audioCtx.createMediaStreamSource(this.stream);

        const destination = audioCtx.createMediaStreamDestination();
        destination.channelCount = 1;

        source.connect(destination);
        this.mediaRecorder = new MediaRecorder(destination.stream, {
            mimeType: 'audio/wav',
        }) as MediaRecorder;

        const openAiClient = container.resolve(OpenAiClient);
        await openAiClient.init();

        this.mediaRecorder.onstop = async (e) => {
            try {
                const audioBase64 = (await this.processRecordedAudio(
                    this.chunks[0],
                )) as string;
                this.stopPlaying();

                const openAiClient = container.resolve(OpenAiClient);
                const spechToText = await openAiClient.sendAudioMessage(
                    audioBase64,
                );

                const audioResponse = await openAiClient.textToSpeech(
                    spechToText.message.text,
                );

                const audioBlob = new Blob([audioResponse.data], {
                    type: 'audio/mp3',
                });

                const audioUrl = URL.createObjectURL(audioBlob);

                const audioBuffer = await this.audioLoader.loadAsync(audioUrl);

                this.positionalSound = new PositionalAudio(
                    this.camera.audioListener,
                );

                this.positionalSound.setBuffer(audioBuffer);

                this.positionalSound.setRefDistance(30);
                this.positionalSound.setRolloffFactor(1);
                this.positionalSound.setDistanceModel('inverse');

                this.dispatchEvent({
                    type: 'soundStarted',
                });

                this.loadingCrircle.visible = false;

                try {
                    this.positionalSound.play();
                } catch (error) {
                    console.error('Audio playback error:', error);
                    this.isProcessing = false;
                    this.updateButtonState();
                }

                this.positionalSound.source.addEventListener('ended', () => {
                    this.dispatchEvent({
                        type: 'soundEnded',
                    });
                    console.log('ended');
                    this.positionalSound.setBuffer(null);
                    this.isProcessing = false;
                    this.updateButtonState();
                });

                // const audio = new Audio(audioUrl);
                //
                // audio.onloadeddata = () => {
                //     this.dispatchEvent({
                //         type: 'soundStarted',
                //     });
                //     this.loadingCrircle.visible = false;
                // };
                //
                // audio.onended = () => {
                //     this.dispatchEvent({
                //         type: 'soundEnded',
                //     });
                //     URL.revokeObjectURL(audioUrl);
                //     this.isProcessing = false;
                //     this.updateButtonState();
                // };
                //
                // audio.onerror = (e) => {
                //     console.error('Audio playback error:', e);
                //     this.isProcessing = false;
                //     this.updateButtonState();
                // };
                //
                // await audio.play();
            } catch (error) {
                console.log('Error:', error);
                if (error.response) {
                    console.log('Response error:', error.response);
                }
                this.isProcessing = false;
                this.loadingCrircle.visible = false;
                this.updateButtonState();
            }
            this.textResponseBlock.visible = true;
            this.chunks = [];
        };

        this.mediaRecorder.ondataavailable = (e) => {
            this.chunks.push(e.data);
        };
    }

    public stopPlaying() {
        if (this.positionalSound) {
            this.positionalSound.stop();
        }
    }

    public addUsernameText(username: string) {
        this.usernameText = new TriokaText();
        this.usernameText.text = username;
        this.usernameText.anchorX = 'center';
        this.usernameText.fontSize = 0.2;
        this.usernameText.position.y = 0;
        this.usernameText.outlineWidth = 0.05;

        this.add(this.usernameText);
    }

    public async startRecording() {
        if (this.isRecording || this.isProcessing) {
            return;
        }

        const hasPermission = await this.checkPermissions();
        if (!hasPermission) {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });
            } catch (error) {
                console.error('Permission denied or audio not available:', error);
                this.dispatchEvent({
                    type: 'permissionDenied',
                    error
                });
                return;
            }
        }

        this.stopPlaying();
        this.isRecording = true;
        this.mediaRecorder.start();

        this.dispatchEvent({
            type: 'startRecording',
        });

        //@ts-ignore
        this.recordingContainer.set({
            backgroundColor: new Color('red'),
        });

        this.updateButtonState();
    }

    private async checkPermissions(): Promise<boolean> {
        try {
            const result = await navigator.permissions.query({ name: 'microphone' as PermissionName });
            return result.state === 'granted';
        } catch (error) {
            console.warn('Could not query permissions:', error);
            return false;
        }
    }


    public keyDown = (event) => {
        if (event.repeat) return;

        const keyCode = event.which;

        if (keyCode === 32 && !this.isProcessing) {
            this.startRecording();
        }
    };

    public keyUp = (event) => {
        const keyCode = event.which;

        if (keyCode === 32) {
            this.stopRecording();
        }
    };

    public stopRecording() {
        if (!this.isRecording) {
            return;
        }

        this.isRecording = false;
        this.isProcessing = true;
        this.mediaRecorder.stop();
        this.loadingCrircle.visible = true;

        this.dispatchEvent({
            type: 'stopRecording',
        });

        this.updateButtonState();
    }

    private updateButtonState() {
        if (!this.recordingContainer) return;

        //@ts-ignore
        this.recordingContainer.set({
            backgroundColor: this.isProcessing
                ? new Color('gray')
                : this.isRecording
                ? new Color('red')
                : new Color('black'),
        });
    }

    public processRecordedAudio(blob) {
        // var blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    public setUsernameTextRotationFromCamera() {
        if (this.renderer.webGLRenderer.xr.isPresenting) {
            this.usernameText.setRotationFromQuaternion(
                //@ts-ignore
                this.renderer.webGLRenderer.xr.getCamera().quaternion,
            );
            return;
        }
        this.usernameText.setRotationFromQuaternion(
            this.camera.instance.quaternion,
        );
    }

    public update() {
        if (this.gamepad !== undefined) {
            this.gamepad.update();
        }

        this.loadingCrircle.update();
    }

    public setModel() {
        const chatbot = this.resources.items.chatbot.scene.clone();
        chatbot.position.set(1.9, -1, 0);
        // chatbot.rotateY(MathUtils.DEG2RAD * 45);
        chatbot.scale.set(3.2, 3.2, 3.2);
        this.add(chatbot);

        this.loadingCrircle.position.set(0, -0.7, 0.4);
        this.loadingCrircle.visible = false;
        this.add(this.loadingCrircle);

        new TWEEN.Tween(chatbot.position)
            .to({
                y: -1.5,
            })
            .duration(4000)
            .yoyo(true)
            .repeat(Infinity)
            .start();
    }

    private createRing() {
        const geometry = new RingGeometry(0.4, 0.45, 32);
        const material = new MeshPhongMaterial({
            color: new Color('white'),
            side: DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: -0.9,
        });

        const ring = new Mesh(geometry, material);
        ring.rotation.x = Math.PI / 2;
        ring.position.y = -2.5;
        this.add(ring);
    }

    public createButton() {
        this.recordingContainer = new Block({
            width: 0.7,
            height: 0.3,
            backgroundColor: new Color('black'),
            justifyContent: 'center',
            contentDirection: 'row-reverse',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSize: 0.07,
            padding: 0.02,
            borderRadius: 0.11,
            backgroundOacity: 0.6,
        });

        const buttonOptions = {
            width: 0.6,
            height: 0.15,
            justifyContent: 'center',
            offset: 0.05,
            margin: 0.02,
            borderRadius: 0.075,
        };

        const hoveredStateAttributes = {
            state: 'hovered',
            attributes: {
                offset: 0.035,
                backgroundColor: new Color(0x999999),
                backgroundOpacity: 1,
                fontColor: new Color(0xffffff),
            },
        };

        const idleStateAttributes = {
            state: 'idle',
            attributes: {
                offset: 0.035,
                backgroundColor: new Color(0x666666),
                backgroundOpacity: 0.3,
                fontColor: new Color(0xffffff),
            },
        };

        const selectedAttributes = {
            offset: 0.02,
            backgroundColor: new Color(0x777777),
            fontColor: new Color(0x222222),
        };

        const startRecordButton = new Block(buttonOptions);
        startRecordButton.position.y = -0.5;

        //@ts-ignore
        startRecordButton.setupState(hoveredStateAttributes);

        //@ts-ignore
        startRecordButton.setupState(idleStateAttributes);

        //@ts-ignore
        startRecordButton.setupState({
            state: 'selected',
            attributes: selectedAttributes,
            onSet: () => {
                if (this.isProcessing) {
                    return;
                }

                if (!this.isRecording) {
                    this.startRecording();
                    return;
                }

                this.stopRecording();
            },
        });

        this.intersectionContainer.addObjectToIntersect(startRecordButton);

        startRecordButton.add(
            new Text({
                content: TranslationService.translate(
                    'vr.study_room_chatbot_press_to_talk',
                ),
            }),
        );

        this.recordingContainer.add(startRecordButton);

        this.recordingContainer.position.set(0, -1.5, 1);
        this.recordingContainer.rotation.x = -0.55;

        this.add(this.recordingContainer);
    }

    public destroy() {
        document.removeEventListener('keydown', this.keyDown);
        document.removeEventListener('keyup', this.keyUp);
    }
}

export class LoadingCrircle extends Mesh {
    public constructor() {
        const geometry = new RingGeometry(0.1, 0.2, 32, 1, 0, Math.PI);
        const material = new MeshBasicMaterial({
            color: new Color('white'),
            side: DoubleSide,
        });

        super(geometry, material);
    }

    public update() {
        this.rotation.z += 0.03;
    }
}
