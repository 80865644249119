export const Utils = {
    convertTimestampToTimeFormatHHMMSS(timestamp) {
        let date = new Date(timestamp * 1000);

        let hours = date.getHours();
        let minutes = '0' + date.getMinutes();
        let seconds = '0' + date.getSeconds();

        return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    },

    stripNonAplhaNumericAndHtml(str) {
        if (!str) {
            return '';
        }

        const normalized = str.normalize('NFD');

        const replaced = normalized.replace(/[^\x00-\x7F]/g, '');
        const replaced2 = replaced.replace(/<[^>]*>?/gm, '');

        const replaced3 = replaced2.replace(
            /(?:<[a-zA-Z]+>)|(?:<\/[a-zA-Z]+>)|(?<B>[<>])/g,
            ' ',
        );

        const replace4 = replaced3.replace(/[\W_]+/g, ' ');

        const replaced5 = replace4.replace(/[\u{0080}-\u{FFFF}]/gu, ' ');

        const replaced6 = replaced5.replace(
            /[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g,
            '',
        );

        const rpelaced7 = replaced6.replace(/[^a-zA-Z0-9,;\-.!? ]/g, '');

        return rpelaced7;
    },

    waitForElm(selector) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    },


    formatPublicationDate(date: string) {
        let fd, y, mm, d, h, m;
        fd = new Date(date);
        y = fd.getUTCFullYear();
        mm = (fd.getMonth() + 1).toString();
        d = fd.getDate().toString();
        h = fd.getHours().toString();
        m = fd.getMinutes().toString();

        return (
            y +
            '.' +
            (mm.length === 1 ? '0' + mm : mm) +
            '.' +
            (d.length === 1 ? '0' + d : d) +
            ', ' +
            (h.length === 1 ? '0' + h : h) +
            ':' +
            (m.length === 1 ? '0' + m : m)
        );
    },



    removeHtmlTags(htmlTags: string) {
        let html = htmlTags.replace('<img>', '||img||');
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        html = tmp.textContent || tmp.innerText;
        return html.replace('||img||', '<img>');
    }
};
export default Utils;
