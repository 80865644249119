import { Observable } from 'rxjs';
import axios, { Axios, AxiosRequestConfig } from 'axios';
import envierments from '../../Environments/envierments';
import { autoInjectable, singleton } from 'tsyringe';
import { IUser, User } from './Models/User';
import IMyContent from './Models/My_content/IMyContent';
import { param } from 'jquery';
import jwtDecode from 'jwt-decode';
import FormData from 'form-data';

export interface IHttpClient {
    post(url: string, data: any): Observable<any>;

    get(url: string): Observable<any>;

    put(url: string, data: any): Observable<any>;

    delete(url: string): Observable<any>;
}

@singleton()
@autoInjectable()
export default class AxiosHttpClient {
    // public axios: Axios;
    public instance: Axios;
    public user: User;

    public myClassesAndSubjectList: any = [];

    public constructor() {
        this.instance = axios.create({
            baseURL: envierments.baseURL,
        });
    }

    public async setRefreshTimer() {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        const currentTime = Math.floor(Date.now() / 1000);
        const refreshTime = (decodedToken['exp'] - currentTime - 60) * 1000;

        setTimeout(() => {
            this.refreshAccessToken().then((res) => {
                localStorage.setItem('token', res.data.token);
                this.setRefreshTimer();
            });
        }, refreshTime);
    }

    public async setInterceptors(res): Promise<void> {
        localStorage.setItem('token', res.data.token);
        localStorage.removeItem('refresh-token');

        this.setRefreshTimer();

        this.instance.interceptors.request.use(
            async (requestConfig: AxiosRequestConfig) => {
                requestConfig.baseURL = envierments.baseURL;

                requestConfig.headers.Authorization = `JWT ${localStorage.getItem(
                    'token',
                )}`;

                return requestConfig;
            },
            async (error) => {
                return Promise.reject(error);
            },
        );
    }

    public async obtainToken() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const user = urlParams.get('user');
        const refreshToken = localStorage.getItem('refresh-token');

        if (refreshToken) {
            const jwtToken = await this.getJWTTokenByRefresh();

            return jwtToken;
        } else if (user) {
            const userCredentials = envierments.users[user];

            const instance = axios.create({
                baseURL: envierments.colyseusHttp,
            });

            const loggedUsers: number[] = (await instance.get<[]>('/')).data;

            if (loggedUsers.includes(Number(user))) {
                const newUrlParams = new URLSearchParams(
                    window.location.search,
                );
                newUrlParams.delete('user');

                window.location.search = newUrlParams.toString();
            }

            return this.instance.post<{ token: string }>(
                'api/v2/jwt/obtain-token/',
                {
                    username: userCredentials.username,
                    password: userCredentials.password,
                },
            );
        }
    }

    public refreshAccessToken() {
        return axios.post<{ token: string }>(
            envierments.baseURL + 'api/v2/jwt/refresh-token/',
            {
                token: localStorage.getItem('token'),
            },
        );
    }

    public getCalendarEvent() {
        return this.instance.get(`/api/v2/school/calendar_events`);
    }

    public getTest() {
        return this.instance.get('/api/v3/notification/');
    }

    public getSkybox() {
        return this.instance.get('/api/v2/vr_settings/skybox/tag');
    }

    public searchSkybox() {
        return this.instance.post('/api/v2/vr_settings/skybox/search', []);
    }

    public getCalendarEventGroup(id: number) {
        return this.instance.get(`/api/v2/school/calendar_events/group/${id}`);
    }

    // getCalendarEventStudent(id:number) {
    //   return this.instance.get(`/api/v2/school/calendar_events/student/${id}`);
    // }

    public getCourses() {
        return this.instance.get<IMyContent[]>('/api/v2/my_content');
    }

    public getCollection(id: number) {
        // return this.instance.get(`/api/v2/my_content/collections/${id}/details`);

        ///api/v2/library/collections/<collection_url>/details
        return this.instance.get(`api/v2/my_content/collections/${id}`);
    }

    //----
    // pobiera informacje o kolekcji w tym url dołączonego pliku
    public getPublisherCollection(id: number) {
        return this.instance.get(`/api/v2/publisher/collection/${id}`);
    }

    //----

    public getDeeplinkRefreshToken() {
        return this.instance.post('api/v2/jwt/get_refresh_by_jwt');
    }

    public getGroups() {
        return this.instance.get('/api/v2/connections/student/groups');
    }

    public getLesson(id: number) {
        return this.instance.get(`/api/v2/lesson/${id}`);
    }

    public getLessons(chapterId: number) {
        return this.instance.get(
            `/api/v2/my_content/collections/chapter/${chapterId}`,
        );
    }

    public getCollectionDetails(id: number) {
        return this.instance.get(
            `/api/v2/my_content/collections/${id}/details`,
        );
    }

    public findUsersByUsername(username: string) {
        return this.instance.get(
            `api/v2/user/search/my_school_students_or_my_friends/?phrase=${username}`,
        );
    }

    public findTeachersOrTeachers(username: string) {
        return this.instance.get(
            `api/v2/user/search/my_school_teachers_or_teachers/${username}`,
        );
    }

    public sendMessage(data: {
        recipient: string;
        subject: string;
        body: string;
        uploaded_audio_file_id: null;
        uploaded_file_id: null;
    }) {
        return this.instance.post('/api/v2/messenger/compose', data);
    }

    public getMessage(messageId: number) {
        return this.instance.get(`/api/v2/messenger/view/${messageId}`);
    }

    public getUser() {
        return this.instance.get<User>('api/v2/user/');
    }

    public getClasses() {
        return this.instance.get('/api/v2/school/classes');
    }

    public getUserList() {
        return this.instance.get('/api/v2/school/members/students');
    }

    public getDashboardSchoolNew() {
        return this.instance.get('/api/v2/school/news');
    }

    public getDashboardClasslNew() {
        return this.instance.get('/api/v2/class/news');
    }

    public getDashboardClassNew(id: number) {
        return this.instance.get(`/api/v2/school/subjects/${id}/news`);
    }

    public getDashboardGroupNew(id: number) {
        return this.instance.get(`/api/v2/connections/groups/${id}/news`);
    }

    public getLessonPlan() {
        return this.instance.get('/api/v2/gradebook/lessonplan');
    }

    public getCourseCollection() {
        return this.instance.get(
            '/api/v2/my_content/collections/sections?favorites=false&useSimpleSerializer=true&include_courses_in_progress=true&include_private_section=exclude',
        );
    }

    //

    public getAssignments() {
        return this.instance.get('/api/v2/assessments/');
    }

    public getAssignmentsSpace(id: number) {
        return this.instance.get(`/api/v2/assignments/${id}/space`);
    }

    public getCalendarAssignments() {
        return this.instance.get(`/api/v2/assignments/`);
    }

    //
    public downloadCharacter(
        characterId: string,
        sessionId,
        voiceResponse: boolean,
    ) {
        return this.instance.post<{
            character_name: string;
            character_id: string;
        }>('/api/v2/convai/character/get', {
            charID: characterId,
            sessionId: sessionId,
            voiceResponse: voiceResponse,
        });
    }

    public conversateWithCharacter(
        characterId: string,
        sessionId,
        voiceResponse: boolean,
        audio?: string,
        userText?: string,
    ) {
        const payload = {
            charID: characterId,
            sessionID: sessionId,
            voiceResponse: 'true',
        };
        if (audio) {
            payload['audio'] = audio;
        }

        return this.instance.post<{
            text: string;
            audio: string;
            sessionID: string;
        }>('/api/v2/convai/character/conversate', payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public getMyClasses() {
        return this.instance.get('/api/v2/school/my_classes');
    }

    public getClassesForStudent() {
        return this.instance.get('/api/v2/school/my_classes');
    }

    // getSemester() {
    //   return this.instance.get('/api/v2/gradebook/yeardefinition');
    // }
    //
    // getClassesForTeacher(semesterId: number) {
    //   return this.instance.get(`/api/v2/gradebook/teacher/subjects_list/semester/${semesterId}`)
    // }

    public getClassesForTeacher() {
        return this.instance.get('/api/v2/gradebook/teacher/subjects_list');
    }

    public getYearDefinition() {
        return this.instance.get('/api/v2/gradebook/yeardefinition');
    }

    public getJWTTokenByRefresh() {
        const emptyInstance = axios.create({
            baseURL: envierments.baseURL,
        });
        const params = {
            refresh_token: localStorage.getItem('refresh-token'),
        };

        return emptyInstance.post<{ token: string; refresh_token: string }>(
            'api/v2/jwt/get_by_refresh',
            params,
        );
    }
}
